import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export type ChatConfigFieldsProps = {};

export const ChatConfigFields = memo(
  function ChatConfigFields({}: ChatConfigFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums', 'chats']);
    const { schema } = useFieldsSchema(
      [
        {
          name: 'chatbotConfig.disclaimerContact',
          label: t('glossary:labels.disclaimerContact'),
        },
      ],
      [t],
    );

    return (
      <Card title={t('glossary:labels.chatbotConfig')} variant='secondary'>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
