import { Language } from '@kanbu/schema/enums';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import zodCs from 'zod-i18n-map/locales/cs/zod.json';
import zodEn from 'zod-i18n-map/locales/en/zod.json';

import { AppSettings } from '@/constants/AppSettings';

import type auth from '../../public/locales/cs/auth.json';
import type chats from '../../public/locales/cs/chats.json';
import type chatUsers from '../../public/locales/cs/chatUsers.json';
import type documents from '../../public/locales/cs/documents.json';
import type enums from '../../public/locales/cs/enums.json';
import type errors from '../../public/locales/cs/errors.json';
import type faqs from '../../public/locales/cs/faqs.json';
import type formFactory from '../../public/locales/cs/formFactory.json';
import type globals from '../../public/locales/cs/globals.json';
import type glossary from '../../public/locales/cs/glossary.json';
import type operators from '../../public/locales/cs/operators.json';
import type organizations from '../../public/locales/cs/organizations.json';
import type registrations from '../../public/locales/cs/registrations.json';
import type threads from '../../public/locales/cs/threads.json';
import type users from '../../public/locales/cs/users.json';

export const DEFAULT_NAMESPACE = 'glossary';
export const UI_LANGUAGES = [Language.Czech, Language.English];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.Czech,
    preload: [Language.Czech],
    ns: ['errors'],
    interpolation: {
      escapeValue: false,
    },
    defaultNS: DEFAULT_NAMESPACE,
    supportedLngs: UI_LANGUAGES,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${
        AppSettings.env === 'production' ? AppSettings.version : Date.now()
      }`,
    },
  });

i18n.addResourceBundle(Language.Czech, 'zod', zodCs);
i18n.addResourceBundle(Language.English, 'zod', zodEn);

z.setErrorMap(zodI18nMap);

export { i18n };

// when new namespace is added, add it here
// we will be creating type just from cs language because that should be always full
export interface I18nCsResources {
  glossary: typeof glossary;
  auth: typeof auth;
  faqs: typeof faqs;
  organizations: typeof organizations;
  globals: typeof globals;
  errors: typeof errors;
  chats: typeof chats;
  chatUsers: typeof chatUsers;
  formFactory: typeof formFactory;
  zod: typeof zodCs;
  users: typeof users;
  documents: typeof documents;
  enums: typeof enums;
  threads: typeof threads;
  operators: typeof operators;
  registrations: typeof registrations;
}

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof DEFAULT_NAMESPACE;
    resources: I18nCsResources;
  }
}
