import { Link } from '@tanstack/react-router';
import { flexRender, type Row } from '@tanstack/react-table';
import { cn, Table } from '@utima/ui';
import { ArrowDownNarrowWide, ArrowUpNarrowWide } from 'lucide-react';
import { useCallback, type MouseEvent } from 'react';

import type { TableConsumerProps, TableMetaAlign } from './dataTableTypes';
import { Loader } from '../loader/Loader';

export type DesktopTableProps<TData> = TableConsumerProps<TData>;

type TDataWithDeletedAt<TData> = TData & { deletedAt?: Date | null };

function getColumnStyle(align: TableMetaAlign | undefined) {
  return cn({
    ['text-center']: align === 'center',
    ['text-left']: align === 'left',
    ['text-right']: align === 'right',
  });
}

/**
 * Get sort string for the new value based on the previous one.
 */
function getSortString(prevValue: string | undefined, value: string): string {
  const [key] = value.split(':');

  // Set ordering to desc for new value since asc is usually default
  if (!prevValue) {
    return `${key}:desc`;
  }

  const [prevKey, prevDirection] = prevValue.split(':');

  // Update new order for the new key
  if (prevKey !== key) {
    return `${key}:desc`;
  }

  return prevDirection === 'desc' ? `${key}:asc` : `${key}:desc`;
}

export function DesktopTable<TData>({
  table,
  loading,
  onRow,
}: DesktopTableProps<TData>) {
  /**
   * Fire onRow event only when clicking on the row/cell,
   * not it's children.
   */
  const handleOnRow = useCallback(
    (
      event: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
      row: Row<TData>,
    ) => {
      event.stopPropagation();

      /**
       * We need to check if the target element is child of row or row itself
       * or if it has data-table="prevent" attribute, which prevents any row
       * action.
       *
       * Elements that go up to row parent are clickable, others not.
       */
      if (event.target instanceof Element) {
        let element: Element | null = event.target;

        do {
          // Prevent any onRow action if any elements up to row have data-table="prevent"
          if (element?.getAttribute('data-table') === 'prevent') {
            break;
          }

          // Parent element is row, fire onRow event
          if (element?.getAttribute('data-table') === 'row') {
            onRow?.(event, row);

            break;
          }

          element = element.parentElement;
        } while (element);
      }
    },
    [onRow],
  );

  const { sort } = table.getState();

  return (
    <div className='relative'>
      <Loader visible={loading} />
      <Table.Root>
        <Table.Head>
          {table.getHeaderGroups().map(headerGroup => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const { columnDef } = header.column;
                const { size, align, sortable } = columnDef.meta ?? {};

                const [sortKey, sortDirection] = sort?.split(':') ?? [];
                const isActiveSort = sortable && sortable == sortKey;

                const headerContent = header.isPlaceholder
                  ? null
                  : flexRender(columnDef.header, header.getContext());

                return (
                  <Table.HCol
                    key={header.id}
                    className={getColumnStyle(align)}
                    style={{
                      maxWidth: size ? `${size}px` : undefined,
                    }}
                  >
                    {sortable ? (
                      //@ts-expect-error - I don't have time to fix broken types right now
                      <Link
                        className='group:[sort-hover] hover:text-light-blue3 focus-visible:text-light-blue3 active:text-light-blue4 inline-flex items-center gap-2 rounded-radius underline outline-none ring-ring transition-all focus-visible:ring-2 focus-visible:ring-offset-2'
                        preload={false}
                        search={old =>
                          ({
                            ...old,
                            sort: getSortString(sort, sortable),
                          }) as never
                        }
                      >
                        {headerContent}
                        {sortable && (
                          <span
                            className={cn(
                              'text-muted-fg/50',
                              isActiveSort && 'text-primary',
                            )}
                          >
                            {isActiveSort && sortDirection === 'desc' ? (
                              <ArrowDownNarrowWide className='size-4' />
                            ) : (
                              <ArrowUpNarrowWide className='size-4' />
                            )}
                          </span>
                        )}
                      </Link>
                    ) : (
                      headerContent
                    )}
                  </Table.HCol>
                );
              })}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map(row => (
            <Table.Row
              key={row.id}
              onClick={event => handleOnRow(event, row)}
              className={cn({
                ['cursor-pointer']: !(row.original as TDataWithDeletedAt<TData>)
                  .deletedAt,
                ['opacity-50']: (row.original as TDataWithDeletedAt<TData>)
                  .deletedAt,
              })}
              data-table='row'
              data-testid='data-table-row'
            >
              {row.getVisibleCells().map(cell => {
                const { align } = cell.column.columnDef.meta ?? {};

                return (
                  <Table.Col key={cell.id} className={getColumnStyle(align)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Col>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
}
