import { createTypedKyClient, type ApiDefinition } from '@kanbu/shared';
import ky from '@toss/ky';
import { z } from 'zod';

import { AppSettings } from '@/constants/AppSettings';
import { useBoundStore } from '@/store/store';

// Define the API schema
const adminApiDefinition = {
  voice: {
    preview: {
      method: 'POST',
      path: 'voice/preview',
      input: z.object({
        voiceId: z.string(),
        text: z.string(),
      }),
      options: {
        raw: true,
      },
    },
    createThread: {
      method: 'POST',
      path: 'voice/create-thread',
      input: z.object({
        chatId: z.string(),
      }),
      options: {
        raw: true,
      },
    },
  },
} as const satisfies ApiDefinition;

/**
 * Ky client instance, with the base URL, other configs
 * and authentication headers.
 */
const kyInstance = ky.create({
  prefixUrl: AppSettings.api.baseURL,
  throwHttpErrors: true,
  hooks: {
    beforeRequest: [
      request => {
        const { jwt } = useBoundStore.getState();

        if (!jwt) {
          return request;
        }

        request.headers.set('Authorization', `Bearer ${jwt}`);

        return request;
      },
    ],
  },
});

/**
 * Typed Ky client for the AI Core API
 */
export const adminApiClient = createTypedKyClient({
  client: kyInstance,
  apiDefinition: adminApiDefinition,
});
