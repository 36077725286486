import { AgentType } from '@kanbu/schema/enums';
import { useLingui } from '@lingui/react/macro';
import { useFieldState } from 'informed';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';

export function TwilioConfigFields() {
  const { hasAccess } = useAccess();
  const { t } = useLingui();

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'voiceConfig.twilio.accountSid',
        label: t`Twilio account SID`,
      },
      {
        name: 'voiceConfig.twilio.authToken',
        label: t`Twilio auth token`,
      },
      {
        name: 'voiceConfig.twilio.number',
        label: t`Twilio number`,
      },
    ],
    [t, hasAccess],
  );

  const typeFieldState = useFieldState('agentType');

  // These fields should be visible only if the agent type is Voice
  if (typeFieldState.value !== AgentType.Voice) {
    return null;
  }

  return (
    <Card title={t`Twilio settings`}>
      <SchemaFields schema={detailsSchema} />
    </Card>
  );
}
