import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';

export const clubSchema = baseOrganizationEntitySchema.extend({
  isActive: z.boolean(),
  clubId: z.number(),
  chainId: z.number(),
  name: z.string(),
  street: z.string(),
  postalCode: z.string(),
  city: z.string(),
  email: z.string().email().nullable(),
  latitude: z.string().nullable(),
  longitude: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  isQrCodeOn: z.boolean().optional(),
  companyEmail: z.string().email().nullish(),
  isVersionZero: z.boolean().optional(),
  isPaidApplication: z.boolean().optional(),
  companyName: z.string().optional(),
});

export const createClubSchema = clubSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  organization: true,
});

export const updateClubSchema = clubSchema
  .partial()
  .required({
    id: true,
    isActive: true,
  })
  .omit({
    organization: true,
  });

export const clubResponseSchema = clubSchema.omit({
  organization: true,
});

export type ClubDTO = z.infer<typeof clubSchema>;
export type CreateClubDTO = z.infer<typeof createClubSchema>;
export type UpdateClubDTO = z.infer<typeof updateClubSchema>;
