import { ContentType } from '@kanbu/schema/enums';
import { Trans } from '@lingui/react/macro';
import { Button, cn, Dialog } from '@utima/ui';
import { File, FileSymlink, Globe } from 'lucide-react';
import { memo, useEffect, useState } from 'react';

import { useEnumTranslations } from '@/locale/enumTranslations';

import { AddExternalDocument } from './AddExternalDocument';
import { AddWebsites } from './AddWebsites';
import { UploadDocuments } from './UploadDocuments';

export type AddDocumentsModalProps = {
  chatId: string;
  defaultOpen?: boolean;
  defaultUrl?: string;
};

export const AddDocumentsModal = memo(function AddDocumentsModal({
  chatId,
  defaultOpen = false,
  defaultUrl,
}: AddDocumentsModalProps) {
  const enumTranslations = useEnumTranslations();
  const [open, setOpen] = useState(defaultOpen);
  const [type, setType] = useState<'website' | 'file' | 'externalDocument'>(
    'website',
  );

  useEffect(() => {
    if (defaultUrl) {
      setType('website');
    }
  }, [defaultUrl]);

  return (
    <Dialog.Root defaultOpen={defaultOpen} open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <Button>
          <Trans>Add resources</Trans>
        </Button>
      </Dialog.Trigger>
      <Dialog.Content className='flex max-h-full min-h-[400px] w-[1000px] max-w-full flex-col'>
        <Dialog.Header>
          <Dialog.Title>
            <Trans>Add resources</Trans>
          </Dialog.Title>
          <Dialog.Description>
            <Trans>
              Below you can upload your own documents, or add websites as
              sources.
            </Trans>
          </Dialog.Description>
        </Dialog.Header>

        <div className='flex gap-4'>
          <Button
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'website' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType('website')}
          >
            <Globe className='size-5' />
            <span>{enumTranslations.contentType[ContentType.Website]}</span>
          </Button>
          <Button
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'externalDocument' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType('externalDocument')}
          >
            <FileSymlink className='size-5' />
            <span>
              {enumTranslations.contentType[ContentType.ExternalDocument]}
            </span>
          </Button>
          <Button
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'file' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType('file')}
          >
            <File className='size-5' />
            <span>{enumTranslations.contentType[ContentType.Document]}</span>
          </Button>
        </div>

        {type === 'website' && (
          <AddWebsites
            chatId={chatId}
            setOpen={setOpen}
            defaultUrl={defaultUrl}
          />
        )}
        {type === 'externalDocument' && (
          <AddExternalDocument chatId={chatId} setOpen={setOpen} />
        )}
        {type === 'file' && <UploadDocuments chatId={chatId} />}
      </Dialog.Content>
    </Dialog.Root>
  );
});
