import type { ChatType } from '@kanbu/schema';
import { AgentType, Role } from '@kanbu/schema/enums';
import { Trans } from '@lingui/react/macro';
import { useNavigate, useRouterState, Link } from '@tanstack/react-router';
import { Dropdown, Menu as UIMenu } from '@utima/ui';
import {
  CircleHelp,
  MailCheck,
  UsersIcon,
  Building,
  Contact,
  ChevronsUpDown,
  PlusCircle,
  LibraryBig,
  BugPlayIcon,
  MessageCircle,
  Edit,
  UserRoundPen,
} from 'lucide-react';
import { memo, useEffect } from 'react';

import { AppSettings } from '@/constants/AppSettings';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

import { MenuLink } from './MenuLink';
import { Protected } from '../protected/Protected';

export type MenuProps = {};

export const Menu = memo(function Menu({}: MenuProps) {
  const routerState = useRouterState();
  const navigate = useNavigate();
  const { data: chats } = trpc.chats.findAll.useQuery();
  const { setChat, chat: currentChat } = useBoundStore(state => ({
    setChat: state.setChat,
    chat: state.chat,
  }));

  const handleChatChange = (chat: ChatType) => {
    setChat(chat);
    const lastMatch = routerState.matches.at(-1);

    if (!lastMatch) {
      return;
    }

    /**
     * When user is currently in a chat-specific route, we need to
     * redirect to the same route with the new chatId.
     */
    if ('chatId' in lastMatch.params) {
      /**
       * We need to parse the path, and always return user max up to 1
       * level deeper after `/chatId`, since we can get stuck in details of
       * components that are not related to selected chat.
       */
      const path = lastMatch.fullPath.split('/');
      const chatIdIndex = path.indexOf('$chatId');
      const newPath = path.slice(0, chatIdIndex + 2).join('/');

      return navigate({
        to: newPath,
        params: {
          chatId: chat.id,
        },
      });
    }
  };

  useEffect(() => {
    if (!chats?.items?.length) {
      return;
    }

    // If there is no current chat, set the first chat as the current chat
    if (!currentChat) {
      setChat(chats?.items?.[0]);
    }

    // If the current chat is not in the list of chats, set the first chat as the current chat
    if (
      currentChat &&
      !chats?.items?.find(chat => chat.id === currentChat.id)
    ) {
      setChat(chats?.items?.[0]);
    }

    // Update existing chat, with new data when refetched
    if (currentChat) {
      const chat = chats?.items?.find(chat => chat.id === currentChat.id);

      if (chat) {
        setChat(chat);
      }
    }
  }, [chats, currentChat, setChat]);

  const hasChats = Array.isArray(chats?.items) && chats?.items?.length > 0;
  const chatId = currentChat?.id;

  return (
    <UIMenu.Root>
      <UIMenu.Content>
        <UIMenu.Item>
          <MenuLink to='/threads' Icon={MailCheck}>
            <Trans>Conversations</Trans>
          </MenuLink>
        </UIMenu.Item>
        <UIMenu.Item>
          <MenuLink to='/chat-users' Icon={UsersIcon}>
            <Trans>Users</Trans>
          </MenuLink>
        </UIMenu.Item>
        <UIMenu.Item>
          <MenuLink to='/registrations' Icon={UserRoundPen}>
            <Trans>Registrations</Trans>
          </MenuLink>
        </UIMenu.Item>
        <UIMenu.Item>
          <MenuLink to='/clubs' Icon={Building}>
            <Trans>Clubs</Trans>
          </MenuLink>
        </UIMenu.Item>

        <UIMenu.Label className='mt-6'>
          <Trans>Agent</Trans>
        </UIMenu.Label>
        <UIMenu.Item>
          <Dropdown.Root modal={false}>
            <Dropdown.Trigger className='m-1 flex w-full items-center justify-between rounded-radius border border-border px-3 py-2.5 text-sm font-medium transition-all hover:bg-accent'>
              {currentChat?.name ?? <Trans>Choose agent</Trans>}
              <ChevronsUpDown className='size-4' />
            </Dropdown.Trigger>
            <Dropdown.Content side='right' align='start' className='min-w-52'>
              {hasChats && (
                <>
                  <Dropdown.Label>
                    <Trans>Agents</Trans>
                  </Dropdown.Label>
                  <Dropdown.Group>
                    {chats?.items?.map(chat => (
                      <Dropdown.Item
                        className='flex items-center justify-between'
                        key={chat.id}
                        onClick={() => handleChatChange(chat)}
                      >
                        {chat.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Group>
                  <Dropdown.Separator />
                </>
              )}
              <Dropdown.Label>
                <Trans>Management</Trans>
              </Dropdown.Label>
              <Dropdown.Group>
                <Dropdown.Item asChild>
                  <Link to='/chats/create'>
                    <PlusCircle className='mr-2 size-4' />
                    <Trans>Add new</Trans>
                  </Link>
                </Dropdown.Item>
              </Dropdown.Group>
            </Dropdown.Content>
          </Dropdown.Root>
        </UIMenu.Item>

        {chatId && (
          <>
            <UIMenu.Item>
              <MenuLink
                to='/chats/$chatId/documents'
                params={{
                  chatId: chatId,
                }}
                Icon={LibraryBig}
              >
                <Trans>Data sources</Trans>
              </MenuLink>
            </UIMenu.Item>
            <UIMenu.Item>
              <MenuLink
                to='/chats/$chatId/edit'
                params={{
                  chatId: chatId,
                }}
                Icon={Edit}
              >
                <Trans>Configure</Trans>
              </MenuLink>
            </UIMenu.Item>
            <UIMenu.Item>
              <MenuLink
                to='/chats/$chatId/faqs'
                Icon={CircleHelp}
                params={{
                  chatId: chatId,
                }}
              >
                <Trans>FAQs</Trans>
              </MenuLink>
            </UIMenu.Item>
            <UIMenu.Item>
              <MenuLink
                to='/chats/$chatId/operators'
                Icon={Contact}
                params={{
                  chatId: chatId,
                }}
              >
                <Trans>Operators</Trans>
              </MenuLink>
            </UIMenu.Item>
            {currentChat?.agentType === AgentType.Chat && (
              <UIMenu.Item>
                <UIMenu.Link asChild>
                  <a
                    target='_blank'
                    href={`${AppSettings.playgroundURL}/?chatId=${chatId}`}
                    className='!text-green-500'
                    rel='noreferrer'
                  >
                    <BugPlayIcon className='size-4 transition-none' />
                    <Trans>Playground</Trans>
                  </a>
                </UIMenu.Link>
              </UIMenu.Item>
            )}
          </>
        )}

        <Protected roles={[Role.SuperAdmin]}>
          <UIMenu.Label className='mt-6 text-amber-600'>
            <Trans>Superadmin</Trans>
          </UIMenu.Label>
          <UIMenu.Item>
            <MenuLink
              to='/organizations'
              Icon={Building}
              activeProps={{ className: 'bg-amber-500/30' }}
            >
              <Trans>Organizations</Trans>
            </MenuLink>
          </UIMenu.Item>
          <UIMenu.Item>
            <MenuLink
              to='/chats'
              Icon={MessageCircle}
              activeProps={{ className: 'bg-amber-500/30' }}
            >
              <Trans>Chats</Trans>
            </MenuLink>
          </UIMenu.Item>
        </Protected>
      </UIMenu.Content>
    </UIMenu.Root>
  );
});
