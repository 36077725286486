import { Button, cn, Dropdown } from '@utima/ui';
import { cva, type VariantProps } from 'class-variance-authority';
import { ChevronDown, MoreHorizontal } from 'lucide-react';
import {
  memo,
  useState,
  type ComponentPropsWithoutRef,
  type ReactNode,
} from 'react';

import { Loader } from '../loader/Loader';

const cardStyles = cva('flex flex-col gap-4 rounded-lg p-4', {
  variants: {
    size: {
      sm: 'gap-3 p-3',
      md: 'gap-4 p-4',
      lg: 'gap-4 p-4',
    },
    variant: {
      plain: 'border-none p-0',
      default: 'border border-border bg-white',
      secondary: 'border border-amber-500/50 bg-amber-500/30',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'default',
  },
});

export type CardProps = {
  title?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  loading?: boolean;
  collapsed?: boolean;
} & VariantProps<typeof cardStyles> &
  Omit<ComponentPropsWithoutRef<'div'>, 'title'>;

export const Card = memo(function Card({
  title,
  children,
  className,
  variant,
  actions = [],
  collapsed = false,
  loading = false,
  size,
  ...restProps
}: CardProps) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <div
      className={cn(cardStyles({ size, variant }), className)}
      {...restProps}
    >
      {title && (
        <div
          className={cn(
            'font-bold text-dark-blue flex items-center justify-between',
            variant !== 'plain' && 'border-b pb-2',
            variant === 'secondary' && 'border-b pb-2',
            {
              ['text-sm']: size === 'md',
              ['text-base']: size === 'md',
              ['text-lg']: size === 'lg',
            },
          )}
        >
          <div
            role='button'
            className='flex w-full items-center justify-between'
            onClick={() => setIsCollapsed(v => !v)}
          >
            {title}
            <div className='flex items-center gap-1'>
              <ChevronDown
                className={cn('size-4 transition-all', {
                  ['-scale-y-100']: isCollapsed,
                })}
              />
              {Array.isArray(actions) && actions?.length ? (
                <Dropdown.Root modal={false}>
                  <Dropdown.Trigger asChild>
                    <Button
                      variant='ghost'
                      size='icon-xs'
                      icon={<MoreHorizontal size={18} />}
                    />
                  </Dropdown.Trigger>
                  <Dropdown.Content side='bottom' align='end'>
                    {actions}
                  </Dropdown.Content>
                </Dropdown.Root>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <div
        className={cn('flex flex-col gap-3 relative', {
          ['hidden']: isCollapsed,
        })}
      >
        <Loader visible={loading} />
        {children}
      </div>
    </div>
  );
});
