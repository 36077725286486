import type { ChatType } from '@kanbu/schema';
import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useChatsCols } from '@/features/chats/useChatsCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chats/')({
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
  component: ChatsPage,
});

export function ChatsPage() {
  const { t } = useTranslation(['globals', 'chats']);
  const columns = useChatsCols();
  const handleQuery = useTableQuery(trpcClient.chats.findAll.query, {
    populate: ['documentsCount', 'embeddingsCount'],
  });

  const handleRow = useDetailRowHandler<ChatType>(row => ({
    to: '/chats/$chatId/edit',
    params: {
      chatId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('chats:texts.manage')}
      breadcrumbs={[{ label: t('globals:routeNames.chats'), to: '/chats' }]}
      createButton={{
        to: '/chats/create',
        label: t('chats:actions.create'),
      }}
      columns={columns}
      queryKey={getQueryKey(trpc.chats.findAll)}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
