import { Link } from '@tanstack/react-router';
import { Button } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { useBoundStore } from '@/store/store';

export function UsageLimitBanner() {
  const { t } = useTranslation(['organizations']);
  const user = useBoundStore(state => state.user);
  const { organization } = user ?? {};

  if (
    !organization ||
    organization.currentMonthlyUsage < organization.monthlyUsageLimit
  ) {
    return null;
  }

  return (
    <div
      className='mb-4 rounded-md bg-red-100 p-4'
      role='alert'
      aria-live='polite'
    >
      <div className='flex items-center justify-between gap-4'>
        <div className='flex-1'>
          <h4 className='mb-1 text-sm font-semibold text-danger'>
            {t('organizations:alerts.usageLimitReached.title')}
          </h4>
          <p
            className='text-sm text-danger/90'
            aria-describedby='usage-limit-action'
          >
            {t('organizations:alerts.usageLimitReached.description')}
          </p>
        </div>
        <Button variant='danger' asChild>
          <Link
            id='usage-limit-action'
            to='/organizations/$organizationId/edit'
            params={{ organizationId: organization.id }}
          >
            {t('organizations:alerts.usageLimitReached.action')}
          </Link>
        </Button>
      </div>
    </div>
  );
}
