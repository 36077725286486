import { z } from 'zod';

import { userSchema } from './userContract';

export const loginSchema = userSchema
  .pick({
    email: true,
  })
  .extend({
    password: z.string().max(255),
  })
  .strict();

export const forgottenPasswordSchema = userSchema
  .pick({
    email: true,
  })
  .strict();

export const verifySchema = userSchema
  .pick({
    email: true,
  })
  .extend({
    token: z.string(),
    password: z.string().max(255),
  })
  .strict();

export const changePasswordSchema = userSchema
  .pick({
    email: true,
  })
  .extend({
    token: z.string(),
    password: z.string().max(255),
  })
  .strict();

export const registerSchema = userSchema
  .pick({
    email: true,
  })
  .extend({
    organization: z.string().max(255),
    url: z.string().max(255),
  })
  .strict();

export type ForgottenPasswordDTO = z.infer<typeof forgottenPasswordSchema>;
export type LoginDTO = z.infer<typeof loginSchema>;
export type VerifyDTO = z.infer<typeof verifySchema>;
export type RegisterDTO = z.infer<typeof registerSchema>;
