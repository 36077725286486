import { z } from 'zod';

import { baseEntitySchema } from './baseContract';
import { ContentType, DocumentStatus } from '../enums';

export const baseDocumentSchema = baseEntitySchema.extend({
  type: z.nativeEnum(ContentType),
  name: z.string(),
  uri: z.string(),
  publicUrl: z.string(),
  status: z.nativeEnum(DocumentStatus),
  content: z.string().nullish(),
  metadata: z.record(z.any()).nullish(),
  parsingMetadata: z.record(z.any()).nullish(),
  embeddingsCount: z.number().nullish(),
  scheduledAt: z.string().datetime().nullish(),
  startedParsingAt: z.string().datetime().nullish(),
  finishedParsingAt: z.string().datetime().nullish(),
  startedEmbeddingAt: z.string().datetime().nullish(),
  finishedEmbeddingAt: z.string().datetime().nullish(),
});

export const documentSchema = baseDocumentSchema.extend({
  name: z.string().optional(),
  type: z.nativeEnum(ContentType),
  chat: z.string().uuid(),
});

export const createDocumentSchema = documentSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

export const createWebsiteDocumentSchema = z
  .object({
    urls: z.array(z.string()),
    chat: z.string().uuid(),
  })
  .strict();

export const createDocumentDocumentSchema = z.object({
  chatId: z.string().uuid(),
  fileName: z.string(),
  contentType: z.string(),
  size: z.number(),
});

export const updateDocumentSchema = documentSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export const mapUrlsSchema = z.object({
  url: z.string(),
});

export const findAllEmbeddingsDocumentSchema = z.object({
  documentId: z.string().uuid(),
});

export const refreshAllDocumentsSchema = z.object({
  chatId: z.string().uuid(),
  statuses: z.array(z.nativeEnum(DocumentStatus)).optional(),
});

export type MapUrlsDTO = z.infer<typeof mapUrlsSchema>;
export type DocumentDTO = z.infer<typeof documentSchema>;
export type CreateDocumentDTO = z.infer<typeof createDocumentSchema>;
export type CreateWebsiteDocumentDTO = z.infer<
  typeof createWebsiteDocumentSchema
>;
export type UpdateDocumentDTO = z.infer<typeof updateDocumentSchema>;
export type FindAllEmbeddingsDocumentDTO = z.infer<
  typeof findAllEmbeddingsDocumentSchema
>;
export type RefreshAllDocumentsDTO = z.infer<typeof refreshAllDocumentsSchema>;
