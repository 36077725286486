import type { ReactNode } from 'react';

import { AddNewButton } from '@/components/actionButtons/AddNewButton';
import {
  DataTable,
  type DataTableProps,
} from '@/components/dataTable/DataTable';

import { ResourcePage, type ResourcePageProps } from './ResourcePage';

interface TablePageProps<TData>
  extends Omit<ResourcePageProps, 'children'>,
    Omit<DataTableProps<TData>, 'ref'> {
  children?: ReactNode;
  createButton?: {
    to: string;
    params?: Record<string, string>;
    label?: string;
  };
}

/**
 * Template for generic table pages, with breadcrumbs and create button.
 */
export function TablePage<TData>({
  title,
  breadcrumbs,
  createButton,
  showSearch = true,
  actions,
  children,
  ...tableProps
}: TablePageProps<TData>) {
  return (
    <ResourcePage
      title={title}
      breadcrumbs={breadcrumbs}
      actions={
        <>
          {actions}
          {createButton && (
            <AddNewButton to={createButton.to} params={createButton.params}>
              {createButton.label}
            </AddNewButton>
          )}
        </>
      }
    >
      {children}
      <DataTable {...tableProps} showSearch={showSearch} />
    </ResourcePage>
  );
}
