import { loginSchema, type LoginDTO } from '@kanbu/schema/contracts';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { TRPCClientError } from '@trpc/client';
import { Button, Separator, toast } from '@utima/ui';
import type * as Form from '@utima/ui-informed';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicForm } from '@/components/form/BasicForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { trpc, trpcClient } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const Route = createFileRoute('/_authLayout/login/')({
  component: LoginPage,
  validateSearch: (search: Record<string, unknown>): { redirect?: string } => {
    return {
      redirect: search.redirect as string,
    };
  },
});

const initialValues: LoginDTO = {
  email: '',
  password: '',
};

export function LoginPage() {
  const { t } = useTranslation(['glossary', 'auth']);
  const navigate = useNavigate();
  const { setJwt, setUser, logout } = useBoundStore(state => ({
    setJwt: state.setJwt,
    setUser: state.setUser,
    logout: state.logout,
  }));

  const loginMutation = trpc.auth.login.useMutation();
  const search = Route.useSearch();
  const [isLoading, setIsLoading] = useState(false);

  // Normal redirect flow after login
  const handleRedirect = async () => {
    if (search.redirect) {
      navigate({ to: search.redirect, replace: true });
    } else {
      navigate({ to: '/' });
    }
  };

  const onSubmit = (formState: Form.TypedFormState<LoginDTO>) => {
    setIsLoading(true);

    return loginMutation.mutate(formState.values, {
      onError: (error: unknown) => {
        logout();

        try {
          if (error instanceof TRPCClientError) {
            const { data } = error;

            if (data.code === 'UNAUTHORIZED') {
              toast.error(t('auth:toasts.login.invalidCredentials.title'), {
                description: t(
                  'auth:toasts.login.invalidCredentials.description',
                ),
              });
            }

            if (data.code === 'NOT_FOUND') {
              toast.error(t('auth:toasts.login.userDoesNotExist.title'), {
                description: t(
                  'auth:toasts.login.userDoesNotExist.description',
                ),
              });
            }
          } else {
            toast.error(t('auth:toasts.login.error.title'), {
              description: t('auth:toasts.login.error.description'),
            });
          }
        } catch {
          setIsLoading(false);
          toast.error(t('auth:toasts.login.error.title'), {
            description: t('auth:toasts.login.error.description'),
          });
        }
      },
      onSuccess: async data => {
        setJwt(data.access);

        // Fetch user me
        const user = await trpcClient.users.me.query();
        setUser(user);

        /**
         * Handle first login flow, by redirecting to the documents modal
         * if the user is logging in for the first time and updating the flag
         * via API.
         */
        if (user.firstLogin) {
          try {
            // Update the flag via API
            const [chats] = await Promise.all([
              trpcClient.chats.findAll.query({
                where: {
                  organization: user.organization.id,
                },
                pagination: {
                  pageSize: 1,
                },
              }),
              trpcClient.users.update.mutate({
                id: user.id,
                firstLogin: false,
              }),
            ]);

            const [chat] = chats.items;

            if (!chat?.id) {
              return handleRedirect();
            }

            // Redirect to documents modal
            navigate({
              to: '/chats/$chatId/documents',
              params: { chatId: chat.id },
              search: { open: user.organization.url ?? '' },
            });
          } catch (error) {
            console.error('Failed to fetch chats:', error);

            return handleRedirect();
          }
        } else {
          return handleRedirect();
        }
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const { schema } = useFieldsSchema(
    [
      {
        name: 'email',
        label: t('glossary:labels.email'),
        required: true,
        uiProps: {
          type: 'email',
        },
      },
      {
        name: 'password',
        label: t('glossary:labels.password'),
        required: true,
        uiProps: {
          type: 'password',
          passwordPreview: true,
        },
      },
    ],
    [t],
  );

  return (
    <BasicForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      zodSchema={loginSchema}
      className='flex w-full flex-col gap-6'
    >
      <SchemaFields schema={schema} />

      <Button
        variant='primary'
        size='lg'
        type='submit'
        disabled={isLoading}
        loading={isLoading}
      >
        {t('glossary:actions.login')}
      </Button>

      <div className='flex flex-row items-center justify-center gap-3'>
        <Link
          to='/forgotten-password'
          className='text-sm hover:text-primary hover:underline'
        >
          {t('auth:actions.toForgottenPassword')}
        </Link>
        <Separator orientation='vertical' className='h-4' />
        <Link
          to='/register'
          className='text-sm font-semibold hover:text-primary hover:underline'
        >
          {t('glossary:actions.register')}
        </Link>
      </div>
    </BasicForm>
  );
}
