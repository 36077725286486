import {
  Gender,
  Agent,
  AgentType,
  CapabilitiesPreset,
  ContentType,
  EFitnessProvider,
  ResponseLengthPreset,
  TonePreset,
} from '@kanbu/schema/enums';
import type { SupportedLanguageCode } from '@kanbu/shared';
import { t } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';
import { useMemo } from 'react';

/**
 * This type contains all the translations for the enums
 * that can be reused in the admin app.
 *
 * It is used mainly to ensure type safetry on the
 * returned translations from `getEnumTranslations()`.
 */
export type EnumTranslations = {
  tonePreset: Record<TonePreset, string>;
  agentType: Record<AgentType, string>;
  agent: Record<Agent, string>;
  capabilities: Record<CapabilitiesPreset, string>;
  capabilitiesPreset: Record<CapabilitiesPreset, string>;
  responseLengthPreset: Record<ResponseLengthPreset, string>;
  contentType: Record<ContentType, string>;
  supportedLanguages: Record<SupportedLanguageCode, string>;
  gender: Record<Gender, string>;
  efitnessProvider: Record<EFitnessProvider, string>;
};

/**
 * This function contains translations for enums and other constants
 * that can be reused in the admin app. It is memoized to avoid
 * re-rendering the same translations.
 */
export function getEnumTranslations() {
  return {
    tonePreset: {
      [TonePreset.Casual]: t`Casual`,
      [TonePreset.Balanced]: t`Balanced`,
      [TonePreset.Friendly]: t`Friendly`,
      [TonePreset.Professional]: t`Professional`,
      [TonePreset.Formal]: t`Formal`,
    },
    agentType: {
      [AgentType.Chat]: t`Chat`,
      [AgentType.Voice]: t`Voice`,
    },
    efitnessProvider: {
      [EFitnessProvider.Croatia]: t`Croatia`,
      [EFitnessProvider.Slovakia]: t`Slovakia`,
      [EFitnessProvider.Czechia]: t`Czechia`,
    },
    gender: {
      [Gender.None]: t`None`,
      [Gender.Male]: t`Male`,
      [Gender.Female]: t`Female`,
    },
    agent: {
      [Agent.ConversationalRagV3]: t`Conversational RAG v3`,
      [Agent.ConversationalRagV2]: t`Conversational RAG v2`,
      [Agent.JAMU]: t`JAMU`,
      [Agent.ConversationalRag]: t`Conversational RAG`,
      [Agent.VoicebotRag]: t`Voicebot RAG`,
      [Agent.VoiceChat]: t`Voice chat`,
      [Agent.FormFactory]: t`Form Factory`,
    },
    capabilitiesPreset: {
      [CapabilitiesPreset.CustomerSupport]: t`Customer support`,
      [CapabilitiesPreset.Sales]: t`Sales`,
      [CapabilitiesPreset.HR]: t`HR`,
    },
    capabilities: {
      [CapabilitiesPreset.CustomerSupport]: t`Answering questions about the company, their products and services to provide users with sufficient information to solve problems`,
      [CapabilitiesPreset.Sales]: t`Answering questions about the company, their products and services for sales purposes`,
      [CapabilitiesPreset.HR]: t`Answering questions about the company, their products and services for user care`,
    },
    contentType: {
      [ContentType.ExternalDocument]: t`External document`,
      [ContentType.Website]: t`Website`,
      [ContentType.Document]: t`Document`,
    },
    responseLengthPreset: {
      [ResponseLengthPreset.Concise]: t`Concise`,
      [ResponseLengthPreset.Balanced]: t`Balanced`,
      [ResponseLengthPreset.Detailed]: t`Detailed`,
    },
    supportedLanguages: {
      en: t`English`,
      de: t`German`,
      fr: t`French`,
      es: t`Spanish`,
      it: t`Italian`,
      nl: t`Dutch`,
      pl: t`Polish`,
      pt: t`Portuguese`,
      da: t`Danish`,
      fi: t`Finnish`,
      no: t`Norwegian`,
      sv: t`Swedish`,
      is: t`Icelandic`,
      ru: t`Russian`,
      uk: t`Ukrainian`,
      cs: t`Czech`,
      sk: t`Slovak`,
      hu: t`Hungarian`,
      bg: t`Bulgarian`,
      ro: t`Romanian`,
      sr: t`Serbian`,
      hr: t`Croatian`,
      mk: t`Macedonian`,
      sl: t`Slovenian`,
      sq: t`Albanian`,
      lt: t`Lithuanian`,
      lv: t`Latvian`,
      et: t`Estonian`,
      el: t`Greek`,
      tr: t`Turkish`,
      he: t`Hebrew`,
      ca: t`Catalan`,
      gd: t`Scottish Gaelic`,
      cy: t`Welsh`,
      ga: t`Irish`,
      bs: t`Bosnian`,
      be: t`Belarusian`,
      ka: t`Georgian`,
      hy: t`Armenian`,
    },
  } satisfies EnumTranslations;
}

/**
 * Use in react components, we memoize the translations
 * to avoid re-rendering the same translations.
 */
export function useEnumTranslations() {
  const { t } = useLingui();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getEnumTranslations(), [t]);
}
