import type { FeedbackType } from '@kanbu/schema';
import { FeedbackCategory, Rating } from '@kanbu/schema/enums';
import { IconThumbDownFilled, IconThumbUpFilled } from '@tabler/icons-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface FeedbackProps {
  feedback: FeedbackType;
}

const Feedback = memo(function Feedback({ feedback }: FeedbackProps) {
  const { t } = useTranslation(['enums']);

  const mapFeedbackCategoryToText = (
    category?: FeedbackCategory,
  ): string | undefined => {
    if (!category) return;

    const map = {
      [FeedbackCategory.InsufficientDetail]: t(
        'feedbackCategories.insufficientDetail',
      ),
      [FeedbackCategory.IrrelevantInformation]: t(
        'feedbackCategories.irrelevantInformation',
      ),
      [FeedbackCategory.MissingInformation]: t(
        'feedbackCategories.missingInformation',
      ),
      [FeedbackCategory.IncorrectInformation]: t(
        'feedbackCategories.incorrectInformation',
      ),
      [FeedbackCategory.Other]: undefined,
    };

    return map[category] || undefined;
  };

  const feedbackCategoryText = mapFeedbackCategoryToText(
    feedback?.category || undefined,
  );

  return (
    <div className='w-[90%]'>
      <div className='-mt-3 ml-0 flex flex-row justify-end'>
        <div className='flex size-9 items-center justify-center rounded-xl bg-black text-white'>
          {feedback.rating === Rating.Negative && (
            <IconThumbDownFilled className='size-5' />
          )}
          {feedback.rating === Rating.Positive && (
            <IconThumbUpFilled className='size-5' />
          )}
        </div>
      </div>
      {(feedbackCategoryText || feedback?.note) && (
        <div className='-mt-7 flex w-max max-w-[85%] flex-col items-start gap-1'>
          {feedbackCategoryText && (
            <div className='relative flex rounded-lg bg-foreground px-3.5 py-2 text-white sm:w-fit'>
              <p> {feedbackCategoryText}</p>
            </div>
          )}
          {feedback?.note && (
            <div className='relative flex rounded-lg bg-foreground px-3.5 py-2 text-white sm:w-fit'>
              <p>{feedback?.note}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Feedback;
