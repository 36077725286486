import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { OperatorForm } from '@/features/operators/OperatorForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute(
  '/_baseLayout/chats/$chatId/operators/create',
)({
  component: OperatorsCreatePage,
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId }),
});

export function OperatorsCreatePage() {
  const { chatId } = Route.useParams();
  const { t } = useTranslation(['globals', 'operators']);
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });

  return (
    <FormPage
      title={t('operators:texts.create')}
      breadcrumbs={[
        {
          label: data.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t('globals:routeNames.operators'),
          to: '/chats/$chatId/operators',
          params: { chatId },
        },
        { label: t('operators:texts.create') },
      ]}
    >
      <OperatorForm chatId={chatId} />
    </FormPage>
  );
}
