export const THEME_PRESETS = {
  light: {
    primary: '#000000',
    primaryFg: '#ffffff',

    secondary: '#000000',
    secondaryFg: '#ffffff',

    background: '#ffffff',
    backgroundSecondary: '#f5f5f5',

    foreground: '#000000',
    placeholder: '#dddddd',
  },
  dark: {
    primary: '#22222A',
    primaryFg: '#f0f0f0',

    secondary: '#C95E6F',
    secondaryFg: '#f0f0f0',

    background: '#32323E',
    backgroundSecondary: '#43434F',

    foreground: '#f0f0f0',
    placeholder: '#43434F',
  },
} as const;

// TODO these should be localized
export const AgentDefaults = {
  name: 'Tina',
  defaultThemePreset: 'dark',
  defaultTheme: THEME_PRESETS.dark,
} as const;
