import type { ThreadType } from '@kanbu/schema';
import { IconX } from '@tabler/icons-react';
import { memo } from 'react';

import { Chat } from '@/components/chat/Chat.tsx';

interface ChatSidebarProps {
  thread: ThreadType | null;
  setDrawerOpen: (value: boolean) => void;
}

export const ChatSidebar = memo(
  ({ thread, setDrawerOpen }: ChatSidebarProps) => {
    return (
      <div className='duration-400 h-screen flex flex-col overflow-y-auto bg-white p-5 transition fade-in'>
        <div className='mb-4 flex flex-row justify-between border-b pb-2'>
          <div className='flex flex-col'>
            <p className='text-red text-xl font-bold'>{thread?.title}</p>
            <p className='text-xs text-muted-fg'>{thread?.id}</p>
          </div>
          <IconX
            className='size-5 cursor-pointer'
            onClick={() => setDrawerOpen(false)}
          />
        </div>
        <Chat thread={thread} />
      </div>
    );
  },
);
