import type { Club } from '@kanbu/schema';
import { Trans } from '@lingui/react/macro';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Switch, cn } from '@utima/ui';
import { ChevronDown, ChevronRight, Loader2 } from 'lucide-react';
import { useState } from 'react';

import { Card } from '@/components/card/Card';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/clubs/')({
  component: ClubsPage,
});

export function ClubsPage() {
  const utils = trpc.useUtils();

  const { data: response, isLoading } = trpc.clubs.findAll.useQuery();

  const mutation = trpc.clubs.update.useMutation({
    onSuccess: () => {
      utils.clubs.findAll.invalidate();
    },
  });

  const onToggleActive = (id: string, isActive: boolean) => {
    mutation.mutate({ id, isActive: !isActive });
  };

  const [collapsedCities, setCollapsedCities] = useState<
    Record<string, boolean>
  >({});

  const toggleCity = (city: string) => {
    setCollapsedCities(prev => ({
      ...prev,
      [city]: !prev[city],
    }));
  };

  const groupClubsByCity = (clubs: Club[]) => {
    const grouped = Object.entries(
      clubs.reduce(
        (acc, club) => {
          const cityBase = club.city.split(' ')[0];
          if (!acc[cityBase]) {
            acc[cityBase] = [];
          }
          acc[cityBase].push(club);

          return acc;
        },
        {} as Record<string, Club[]>,
      ),
    ).map(([city, clubs]) => ({
      city,
      clubs: clubs.sort((a, b) => a.name.localeCompare(b.name)),
      hasActiveClubs: clubs.some(club => club.isActive),
    }));

    return grouped.sort((a, b) => {
      if (a.hasActiveClubs && !b.hasActiveClubs) return -1;
      if (!a.hasActiveClubs && b.hasActiveClubs) return 1;

      return a.city.localeCompare(b.city);
    });
  };

  if (isLoading) {
    return (
      <div className='flex size-full flex-1 items-center justify-center'>
        <Loader2 className='size-8 animate-spin' />
      </div>
    );
  }

  const { items: clubs } = response || { items: [] };
  const groupedClubs = groupClubsByCity(clubs as never as Club[]);

  if (Object.keys(collapsedCities).length === 0) {
    const initialCollapsedState = Object.fromEntries(
      groupedClubs.map(({ city, hasActiveClubs }) => [city, !hasActiveClubs]),
    );
    setCollapsedCities(initialCollapsedState);
  }

  return (
    <div className='p-4'>
      <h1 className='mb-4 text-2xl font-bold'>
        <Trans>Club Management</Trans>
      </h1>

      <div className='grid gap-4'>
        {groupedClubs.map(({ city, clubs, hasActiveClubs }) => (
          <div key={city}>
            <Button
              variant='ghost'
              onClick={() => toggleCity(city)}
              className='flex w-full items-center justify-start gap-2 px-2 font-medium'
            >
              {collapsedCities[city] ? (
                <ChevronRight className='size-4' />
              ) : (
                <ChevronDown className='size-4' />
              )}
              <span>
                {city} ({clubs.filter(club => club.isActive).length}/
                {clubs.length})
              </span>
              {hasActiveClubs && (
                <span className={cn('ml-2 text-xs', 'text-success')}>
                  • Active
                </span>
              )}
            </Button>

            {!collapsedCities[city] && (
              <div className='grid gap-2 pl-6 pt-2'>
                {clubs.map(club => (
                  <Card key={club.clubId} variant='default' size='sm'>
                    <div className='flex flex-row items-center justify-between'>
                      <div className='flex-1'>
                        <h3 className='font-medium'>{club.name}</h3>
                        <p className='text-xs text-gray-500'>
                          {club.street}, {club.postalCode} {club.city}
                          {club.phoneNumber && ` • ${club.phoneNumber}`}
                        </p>
                      </div>

                      <div className='flex items-center gap-4'>
                        <Switch
                          data-table='prevent'
                          checked={club.isActive}
                          disabled={mutation.isPending}
                          onClick={() => onToggleActive(club.id, club.isActive)}
                        />
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
