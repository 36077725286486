import { Link, type Register } from '@tanstack/react-router';
import { Menu as UIMenu, cn } from '@utima/ui';
import type { LucideProps } from 'lucide-react';
import { memo, type ElementType, type ReactNode } from 'react';
import type { LiteralUnion } from 'type-fest';

export type MenuLinkProps = {
  Icon: ElementType<LucideProps>;
  disabled?: boolean;
  to: LiteralUnion<keyof Register['router']['routesByPath'], string>;
  params?: Record<string, string>;
  children: ReactNode;
  activeProps?: Record<string, string>;
};

export const MenuLink = memo(function MenuLink({
  Icon,
  to,
  children,
  disabled,
  params,
  activeProps,
}: MenuLinkProps) {
  return (
    <UIMenu.Link disabled={disabled} asChild>
      <Link
        preload={false}
        to={to}
        params={params}
        activeProps={{ className: 'bg-primary/30', ...activeProps }}
      >
        <Icon className={cn('size-4 transition-none')} /> {children}
      </Link>
    </UIMenu.Link>
  );
});
