import { registerSchema, type RegisterDTO } from '@kanbu/schema/contracts';
import {
  createFileRoute,
  Link,
  type SearchSchemaInput,
  useNavigate,
} from '@tanstack/react-router';
import { TRPCClientError } from '@trpc/client';
import { Button, toast } from '@utima/ui';
import type * as Form from '@utima/ui-informed';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicForm } from '@/components/form/BasicForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { trpc } from '@/services/trpc';

type RegistrationSearchParams = {
  organization: string;
  email: string;
  url: string;
};

export const Route = createFileRoute('/_authLayout/register/')({
  component: () => <RegisterPage />,
  // TODO: Change to zod (https://tanstack.com/router/v1/docs/framework/react/guide/search-params#zod)
  validateSearch: (
    search: {
      organization?: string;
      email?: string;
      url?: string;
    } & SearchSchemaInput,
  ): RegistrationSearchParams => {
    return {
      organization: (search.organization as string) ?? '',
      email: (search.email as string) ?? '',
      url: (search.url as string) ?? '',
    };
  },
});

export function RegisterPage() {
  const { t } = useTranslation(['glossary', 'auth']);
  const navigate = useNavigate();
  const { organization, email, url } = Route.useSearch();

  const registerMutation = trpc.auth.register.useMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formState: Form.TypedFormState<RegisterDTO>) => {
    setIsLoading(true);

    return registerMutation.mutate(
      { ...formState.values, url },
      {
        onError: async (error: unknown) => {
          if (error instanceof TRPCClientError) {
            const { data } = error;

            if (data.code === 'CONFLICT') {
              toast.error(t('auth:toasts.register.userAlreadyExists.title'), {
                description: t(
                  'auth:toasts.register.userAlreadyExists.description',
                ),
              });
            }
          }
        },
        onSuccess: async () => {
          navigate({
            to: '/register/sent',
            search: { email: formState.values.email },
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const { schema } = useFieldsSchema(
    [
      {
        name: 'organization',
        label: t('glossary:labels.organizationName'),
        required: true,
        initialValue: organization,
      },
      {
        name: 'email',
        label: t('glossary:labels.email'),
        required: true,
        initialValue: email,
        uiProps: {
          type: 'email',
        },
      },
    ],
    [t],
  );

  return (
    <BasicForm
      onSubmit={onSubmit}
      zodSchema={registerSchema}
      className='flex w-full flex-col gap-6'
    >
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:texts.register.title')}
      </h1>

      <SchemaFields schema={schema} />

      <Button
        variant='primary'
        size='lg'
        type='submit'
        disabled={isLoading}
        loading={isLoading}
      >
        {t('glossary:actions.register')}
      </Button>
      <div className='flex flex-col items-center justify-center gap-3'>
        <Link
          to='/login'
          className='text-sm hover:text-primary hover:underline'
        >
          {t('glossary:actions.login')}
        </Link>
      </div>
    </BasicForm>
  );
}
