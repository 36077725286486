import { Role } from '@kanbu/schema/enums';
import { formatUtils } from '@kanbu/shared';
import { Link, useNavigate } from '@tanstack/react-router';
import { Avatar, Dropdown, Separator, toast } from '@utima/ui';
import {
  Settings,
  ChevronsUpDown,
  SquareArrowLeft,
  Users,
  Building,
} from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AppSettings } from '@/constants/AppSettings';
import { OrganizationSwitch } from '@/features/organizations';
import { trpc } from '@/services/trpc';

import { useBoundStore } from '../../store/store';
import { Protected } from '../protected/Protected';

export const UserDropdown = memo(function UserDropdown() {
  const navigate = useNavigate();
  const { t } = useTranslation(['glossary', 'auth', 'globals']);

  const logout = useBoundStore(state => state.logout);
  const user = useBoundStore(state => state.user);
  const logoutMutation = trpc.auth.logout.useMutation({
    onError: error => {
      toast.error(t('auth:toasts.logout.error.title'), {
        description: t('auth:toasts.logout.error.description'),
      });
    },
    onSettled: () => {
      logout();
      navigate({ to: '/login' });
    },
  });

  if (!user) {
    return null;
  }

  return (
    <div className='mt-6 flex w-full flex-col items-center gap-3 overflow-hidden'>
      <Protected roles={[Role.SuperAdmin]}>
        <OrganizationSwitch />
      </Protected>
      <p className='text-xs font-medium text-menu-fg/50'>
        v{AppSettings.version}
      </p>
      <Separator />
      <Dropdown.Root>
        <Dropdown.Trigger className='group flex w-full items-center justify-between gap-3 rounded-radius p-2 transition-all hover:bg-accent'>
          <div className='flex items-center gap-3'>
            <Avatar
              className='cursor-pointer'
              src={user?.photo}
              fallback={formatUtils.initials(
                formatUtils.fullName({
                  firstName: user?.firstName || '',
                  lastName: user?.lastName || '',
                }),
              )}
            />
            <div className='grow text-left'>
              <p className='text-sm font-medium'>
                {formatUtils.fullName({
                  firstName: user.firstName || user.email,
                  lastName: user.lastName || '',
                })}
              </p>
              <p className='text-xs text-foreground/50'>{user.email}</p>
            </div>
          </div>
          <div className='flex shrink-0 items-center justify-center'>
            <ChevronsUpDown className='size-4' />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Content side='right' align='end' className='min-w-52'>
          <Dropdown.Label>{t('glossary:labels.organization')}</Dropdown.Label>
          <Dropdown.Item className='flex items-center gap-2' asChild>
            <Link
              to='/organizations/$organizationId/edit'
              params={{ organizationId: user.organization.id }}
            >
              <Building className='size-4' />
              {t('glossary:labels.settings')}
            </Link>
          </Dropdown.Item>
          <Dropdown.Item className='flex items-center gap-2' asChild>
            <Link to='/users'>
              <Users className='size-4' />
              {t('globals:routeNames.users')}
            </Link>
          </Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Label>{t('glossary:labels.user')}</Dropdown.Label>
          <Dropdown.Item className='flex items-center gap-2' asChild>
            <Link to='/users/profile'>
              <Settings className='size-4' />
              {t('glossary:labels.profile')}
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            className='flex items-center gap-2'
            onClick={() => logoutMutation.mutate()}
          >
            <SquareArrowLeft className='size-4' />
            {t('glossary:actions.logout')}
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Root>
    </div>
  );
});
