import {
  createFileRoute,
  Outlet,
  redirect,
  Link,
} from '@tanstack/react-router';
import { Layout } from '@utima/ui';
import { useEffect } from 'react';

import logo from '@/assets/images/logo-black.svg';
import { LanguageSwitcher } from '@/components/languageSwitcher/LanguageSwitcher';
import { Menu } from '@/components/menu/Menu';
import { UserDropdown } from '@/components/userDropdown/UserDropdown';
import { UsageLimitBanner } from '@/features/organizations/components/UsageLimitBanner';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const Route = createFileRoute('/_baseLayout')({
  beforeLoad: async () => {
    if (useBoundStore.getState().jwt === null) {
      throw redirect({
        to: '/login',
        search: {
          // Use the current location to power a redirect after login
          redirect: location.pathname,
        },
      });
    }
  },
  component: BaseLayout,
});

export function BaseLayout() {
  const setUser = useBoundStore(state => state.setUser);
  const userQuery = trpc.users.me.useQuery(undefined, {
    staleTime: 15_000 * 60, // 15 minutes,
  });

  useEffect(() => {
    if (!userQuery.data) {
      return;
    }

    setUser(userQuery.data);
  }, [setUser, userQuery.data]);

  return (
    <Layout.Root hasHeader={false} headerHeight={0}>
      <Layout.Sidebar className='flex flex-col justify-between border-r p-3 text-foreground'>
        <div className='flex flex-col justify-between'>
          <div>
            <div className='mx-1 my-2 mb-3 flex items-center justify-between'>
              <Link to='/' className='block p-1'>
                <img src={logo} alt='Kanbu.ai' className='h-8 w-auto' />
              </Link>
              <div>
                <LanguageSwitcher />
              </div>
            </div>
            <Menu />
          </div>
          <UserDropdown />
        </div>
      </Layout.Sidebar>
      <Layout.Content className='bg-gray-50 p-6'>
        <div className='container mx-auto'>
          <UsageLimitBanner />
          <Outlet />
        </div>
      </Layout.Content>
    </Layout.Root>
  );
}
