/**
 * Copies the given text to the clipboard.
 *
 * @param text - The text to copy.
 * @returns A Promise that resolves once the text has been copied.
 */
export async function copyToClipboard(text: string): Promise<void> {
  if (!navigator.clipboard) {
    // Fallback: If the Clipboard API isn't available, try the old approach
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Move out of viewport so it's not visible
    textArea.style.position = 'fixed';
    textArea.style.left = '-99999px';

    document.body.append(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } finally {
      textArea.remove();
    }

    return;
  }

  // Modern approach: use the Clipboard API
  await navigator.clipboard.writeText(text);
}
