import { formatUtils } from '@kanbu/shared';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { UserForm } from '@/features/users/components/UserForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/users/$userId/edit')({
  component: UsersEditPage,
  loader: async ({ context: { trpcUtils }, params: { userId } }) =>
    trpcUtils.users.findOne.ensureData({ id: userId }),
});

export function UsersEditPage() {
  const { userId } = Route.useParams();
  const { t } = useTranslation(['globals', 'users']);
  const [data] = trpc.users.findOne.useSuspenseQuery({ id: userId });

  return (
    <FormPage
      title={`${t('users:texts.edit')} - ${formatUtils.fullName({ firstName: data.firstName || data.email, lastName: data.lastName || '' })}`}
      breadcrumbs={[
        { label: t('globals:routeNames.users'), to: '/users' },
        {
          label: formatUtils.fullName({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
          }),
          to: '/users/$userId/edit',
          params: { userId },
        },
      ]}
    >
      <UserForm data={data} />
    </FormPage>
  );
}
