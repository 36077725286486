import { Select, SelectItem } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '@/hooks/useLanguage';
import { UI_LANGUAGES } from '@/services/deprecated_i18n';

export function LanguageSwitcher() {
  const { setLanguage } = useLanguage();
  const { i18n } = useTranslation();

  return (
    <div className='w-20'>
      <Select
        value={i18n.language}
        onValueChange={value => {
          i18n.changeLanguage(value);
          setLanguage(value);
        }}
      >
        {UI_LANGUAGES.map(lang => (
          <SelectItem key={lang} value={lang}>
            {lang.toUpperCase()}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
}
