import { useLingui } from '@lingui/react/macro';
import type { KyResponse } from '@toss/ky';
import { Button } from '@utima/ui';
import { StopCircle, PlayCircle, Loader2 } from 'lucide-react';
import { useState, useRef, useEffect } from 'react';

import { adminApiClient } from '@/services/apiClient';

export function VoicePreview({
  voiceId,
  text,
}: {
  voiceId: string;
  text: string;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLingui();

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset to beginning
      URL.revokeObjectURL(audioRef.current.src); // Clean up the URL
      audioRef.current = null;
    }
    setIsPlaying(false);
  };

  const handlePreview = async () => {
    setIsLoading(true);

    try {
      if (isPlaying) {
        stopAudio();

        return;
      }

      const response = (await adminApiClient.voice.preview(
        {
          voiceId,
          text,
        },
        {
          raw: true,
        },
      )) as KyResponse;

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener('ended', stopAudio);
      audioRef.current = audioElement;
      audioElement.play();

      setIsPlaying(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error playing voice preview:', error);
      stopAudio();
    } finally {
      setIsLoading(false);
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopAudio();
    };
  }, []);

  return (
    <Button
      variant='ghost'
      outline
      size='icon-sm'
      disabled={isLoading}
      onClick={handlePreview}
      className='shrink-0'
      title={isPlaying ? t`Stop preview` : t`Play preview`}
    >
      {isLoading ? (
        <Loader2 className='size-5 animate-spin' />
      ) : isPlaying ? (
        <StopCircle className='size-5 text-red-500' />
      ) : (
        <PlayCircle className='size-5 text-success' />
      )}
    </Button>
  );
}
