import { ResourcePage, type ResourcePageProps } from './ResourcePage';

export interface FormPageProps extends ResourcePageProps {}

/**
 * Template for edit pages, with breadcrumbs.
 */
export function FormPage({
  title,
  breadcrumbs,
  children,
  ...restProps
}: FormPageProps) {
  return (
    <ResourcePage title={title} breadcrumbs={breadcrumbs} {...restProps}>
      {children}
    </ResourcePage>
  );
}
