import { RecordType } from '@kanbu/schema/enums';
import { formatUtils } from '@kanbu/shared';
import { Table } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { trpc } from '@/services/trpc';

interface OrganizationUsageReportProps {
  organizationId: string;
}

export function OrganizationUsageReport({
  organizationId,
}: OrganizationUsageReportProps) {
  const { t } = useTranslation(['glossary', 'organizations', 'enums']);
  const { data, isLoading, error } = trpc.organizations.usageReport.useQuery({
    id: organizationId,
  });

  if (isLoading) {
    return <div className='mt-8 p-4'>Loading usage report...</div>;
  }

  if (error) {
    return (
      <div className='mt-8 p-4 text-danger'>
        Failed to load usage report: {error.message}
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div className='mt-8'>
      <h2 className='mb-4 text-2xl font-bold'>
        {t('organizations:texts.usageReport')} -{' '}
        <span className='text-danger'>
          This is now for debugging, only SuperAdmin can see it.
        </span>
      </h2>

      {data.months.map(month => (
        <Card key={month.month} className='mb-8 shadow-sm'>
          <div className='p-8'>
            <h3 className='mb-6 flex items-center justify-between'>
              <span className='text-2xl font-bold text-foreground'>
                {new Date(month.month).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                })}
              </span>
              <span className='text-2xl font-bold text-emerald-600'>
                {formatUtils.currencyDollar(month.totalCost)}
              </span>
            </h3>

            {month.byCategory.map(category => (
              <div key={category.category} className='mb-8 last:mb-0'>
                <h4 className='mb-4 flex items-center justify-between'>
                  <span className='text-xl font-semibold text-foreground/90'>
                    {t(`enums:recordCategory.${category.category}`)}
                  </span>
                  <span className='text-lg font-semibold text-emerald-600/90'>
                    {formatUtils.currencyDollar(category.totalCost)}
                  </span>
                </h4>

                {category.byType.map(typeStats => (
                  <div key={typeStats.type} className='mb-6 last:mb-4'>
                    <div className='mb-3 flex items-center justify-between'>
                      <span className='text-base font-medium text-foreground/80'>
                        {t(`enums:recordType.${typeStats.type}`)}
                      </span>
                      <span className='text-base font-medium text-emerald-600/80'>
                        {formatUtils.currencyDollar(typeStats.totalCost)}
                      </span>
                    </div>

                    <div className='bg-card/50 rounded-lg shadow-sm backdrop-blur-sm'>
                      <Table.Root>
                        <Table.Head>
                          <Table.Row className='border-b border-border/50 hover:bg-transparent'>
                            <Table.HCol className='w-[180px] text-sm font-medium text-muted-fg'>
                              {t('organizations:texts.provider')}
                            </Table.HCol>
                            <Table.HCol className='w-[180px] text-sm font-medium text-muted-fg'>
                              {t('organizations:texts.model')}
                            </Table.HCol>
                            <Table.HCol className='text-right text-sm font-medium text-muted-fg'>
                              {t('organizations:texts.operations')}
                            </Table.HCol>
                            {typeStats.type === RecordType.Llm && (
                              <>
                                <Table.HCol className='text-right text-sm font-medium text-muted-fg'>
                                  {t('organizations:texts.inputTokens')}
                                </Table.HCol>
                                <Table.HCol className='text-right text-sm font-medium text-muted-fg'>
                                  {t('organizations:texts.outputTokens')}
                                </Table.HCol>
                                <Table.HCol className='text-right text-sm font-medium text-muted-fg'>
                                  {t('organizations:texts.totalTokens')}
                                </Table.HCol>
                                <Table.HCol className='text-right text-sm font-medium text-muted-fg'>
                                  {t('organizations:texts.inputCost')}
                                </Table.HCol>
                                <Table.HCol className='text-right text-sm font-medium text-muted-fg'>
                                  {t('organizations:texts.outputCost')}
                                </Table.HCol>
                              </>
                            )}
                            {typeStats.type !== RecordType.Llm && (
                              <Table.HCol className='text-right text-sm font-medium text-muted-fg'>
                                {t('organizations:texts.units')}
                              </Table.HCol>
                            )}
                            <Table.HCol className='w-[150px] text-right text-sm font-medium text-muted-fg'>
                              {t('organizations:texts.totalCost')}
                            </Table.HCol>
                          </Table.Row>
                        </Table.Head>
                        <Table.Body>
                          {typeStats.byModel.map((modelStats, idx) => (
                            <Table.Row
                              key={`${modelStats.provider}-${modelStats.model}-${idx}`}
                              className='transition-colors hover:bg-muted/30'
                            >
                              <Table.Col className='font-medium text-foreground/90'>
                                {modelStats.provider}
                              </Table.Col>
                              <Table.Col className='font-mono text-sm text-foreground/80'>
                                {modelStats.model}
                              </Table.Col>
                              <Table.Col className='text-right tabular-nums text-foreground/80'>
                                {formatUtils.number(modelStats.count)}
                              </Table.Col>
                              {typeStats.type === RecordType.Llm && (
                                <>
                                  <Table.Col className='text-right tabular-nums text-foreground/80'>
                                    {formatUtils.number(
                                      modelStats.inputTokens || 0,
                                    )}
                                  </Table.Col>
                                  <Table.Col className='text-right tabular-nums text-foreground/80'>
                                    {formatUtils.number(
                                      modelStats.outputTokens || 0,
                                    )}
                                  </Table.Col>
                                  <Table.Col className='text-right tabular-nums text-foreground/80'>
                                    {formatUtils.number(
                                      (modelStats.inputTokens || 0) +
                                        (modelStats.outputTokens || 0),
                                    )}
                                  </Table.Col>
                                  <Table.Col className='text-right tabular-nums text-emerald-600/80 dark:text-emerald-400/80'>
                                    {formatUtils.currencyDollar(
                                      modelStats.inputCost || 0,
                                    )}
                                  </Table.Col>
                                  <Table.Col className='text-right tabular-nums text-emerald-600/80 dark:text-emerald-400/80'>
                                    {formatUtils.currencyDollar(
                                      modelStats.outputCost || 0,
                                    )}
                                  </Table.Col>
                                </>
                              )}
                              {typeStats.type !== RecordType.Llm && (
                                <Table.Col className='text-right tabular-nums text-foreground/80'>
                                  {formatUtils.number(modelStats.totalUnits)}
                                </Table.Col>
                              )}
                              <Table.Col className='text-right font-semibold tabular-nums text-emerald-600 dark:text-emerald-400'>
                                {formatUtils.currencyDollar(
                                  modelStats.totalCost,
                                )}
                              </Table.Col>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table.Root>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            <div className='mt-6 flex items-center justify-end gap-3 text-sm'>
              <span className='font-medium text-muted-fg'>
                {t('organizations:texts.totalOperations')}:
              </span>
              <span className='font-bold tabular-nums text-foreground'>
                {formatUtils.number(month.count)}
              </span>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}
