import type { ChatType } from '@kanbu/schema';
import type { EvalTestResults } from '@kanbu/schema/contracts';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

/**
 * Hook for generating and downloading the Excel file with the evaluation results.
 */
export function useEvaluationXls() {
  const { t } = useTranslation(['glossary']);
  const detailHeaders = useMemo(() => {
    return [
      t('glossary:labels.threadId'),
      t('glossary:labels.messageIndex'),
      t('glossary:labels.question'),
      t('glossary:labels.answer'),
      t('glossary:labels.expectedAnswer'),
      t('glossary:labels.correct'),
      t('glossary:labels.accuracy'),
      t('glossary:labels.explanation'),
      t('glossary:labels.latency'),
    ];
  }, [t]);

  /**
   * Generates the Excel workbook from the evaluation results
   */
  const generateWorkbook = useCallback(
    (data: EvalTestResults, chat?: ChatType) => {
      const wb = XLSX.utils.book_new();

      // Generate summary data
      const filteredMessages = data.filter(
        test => test.messages.length > 0 && test.messages[0]?.results,
      );

      const totalMessages = filteredMessages.reduce(
        (acc, test) => acc + test.messages.length,
        0,
      );

      const totalCorrect = filteredMessages.reduce(
        (acc, test) =>
          acc + test.messages.filter(m => m.results.isCorrect).length,
        0,
      );

      let medianAccuracy = filteredMessages.reduce(
        (acc, test) => acc + test.messages[0].results.accuracyPercentage,
        0,
      );
      medianAccuracy = medianAccuracy > 0 ? medianAccuracy / data.length : 0;

      // Summary worksheet
      const summaryData = [
        ['Evaluation Summary'],
        ['Date', new Date().toLocaleString()],
        ['Total Messages', totalMessages],
        ['Correct Answers', totalCorrect],
        ['Incorrect Answers', totalMessages - totalCorrect],
        ['Median Accuracy', formatUtils.percentage(medianAccuracy)],
      ];

      // Add chat configuration if available
      if (chat) {
        summaryData.push(
          [''],
          ['Chat Configuration'],
          ['Chat ID', chat.id],
          ['Chat Name', chat.name],
          ['Agent', chat.agent],
          ['Model', chat.model],
          ['Index Version', chat.embeddingsVersion],
          ...(Object.entries(chat.agentConfig || {}) as any)
            .filter(([key]: any[]) => !['systemPrompt', 'tone'].includes(key))
            .map(([key, value]: any[]) => [key, value]),
        );
      }

      const summaryWs = XLSX.utils.aoa_to_sheet(summaryData);

      // Details worksheet
      const detailsData = data.flatMap((result, threadIndex) =>
        result.messages.map((message, messageIndex) => [
          `Thread ${threadIndex + 1}`,
          messageIndex + 1,
          message.question,
          message.answer,
          message.expectedOutput,
          message.results.isCorrect
            ? t('glossary:labels.yes')
            : t('glossary:labels.no'),
          formatUtils.percentage(message.results.accuracyPercentage),
          message.results.explanation,
          `${message.latency}ms`,
        ]),
      );

      const detailsWs = XLSX.utils.aoa_to_sheet([
        detailHeaders,
        ...detailsData,
      ]);

      // Add worksheets to workbook
      XLSX.utils.book_append_sheet(wb, summaryWs, 'Summary');
      XLSX.utils.book_append_sheet(wb, detailsWs, 'Details');

      // Apply some styling
      summaryWs['!cols'] = [{ wch: 15 }, { wch: 50 }];
      detailsWs['!cols'] = detailHeaders.map(() => ({ wch: 20 }));

      return wb;
    },
    [detailHeaders, t],
  );

  /**
   * Downloads the Excel file with the evaluation results
   */
  const downloadXls = useCallback(
    (data: EvalTestResults, chatId: string, chat?: ChatType) => {
      const wb = generateWorkbook(data, chat);
      XLSX.writeFile(
        wb,
        `${formatUtils.date(new Date(), DateFormat.DateInput)}_${t('glossary:labels.evaluation')}_${chatId}_${chat?.agent}_${chat?.name}.xlsx`,
      );
    },
    [generateWorkbook, t],
  );

  return { generateWorkbook, downloadXls };
}
