import './services/deprecated_i18n';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { FormTranslationsContext } from '@utima/ui-informed';
import { StrictMode, Suspense, useEffect } from 'react';

import { Loader } from './components/loader/Loader';
import { useFormTranslations } from './hooks/useFormTranslations';
import { useLanguage } from './hooks/useLanguage';
import { dynamicActivate } from './locale/i18n';
import { queryClient } from './services/queryClient';
import { router } from './services/router';
import { trpc, trpcQueryClient } from './services/trpc';

import './app.css';

export function App() {
  const translations = useFormTranslations();
  const { language } = useLanguage();

  // Load translations
  useEffect(() => {
    dynamicActivate(language);
  }, [language]);

  return (
    <StrictMode>
      <I18nProvider i18n={i18n}>
        <Suspense fallback={<Loader />}>
          <trpc.Provider client={trpcQueryClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <FormTranslationsContext.Provider value={translations}>
                <RouterProvider router={router} />
              </FormTranslationsContext.Provider>
            </QueryClientProvider>
          </trpc.Provider>
        </Suspense>
      </I18nProvider>
    </StrictMode>
  );
}
