import type { DocumentStatus } from '@kanbu/schema/enums';
import { Progress } from '@utima/ui';
import { Circle } from 'lucide-react';
import { memo, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type DocumentsStatusItemProps = {
  label: string;
  count: number;
  total: number;
  icon?: ReactNode;
  variant?: 'info' | 'success' | 'warning' | 'danger';
  subItems?: Array<{
    status: DocumentStatus;
    count: number;
  }>;
};

export const DocumentsStatusItem = memo(function DocumentsStatusItem({
  label,
  count,
  total,
  icon,
  variant = 'info',
  subItems,
}: DocumentsStatusItemProps) {
  const { t } = useTranslation(['enums']);
  const percentage = total > 0 ? (count / total) * 100 : 0;
  const filteredSubItems = subItems?.filter(item => item.count > 0);

  return (
    <div className='rounded-radius border border-border p-4 shadow-sm'>
      <div className='mb-1 flex items-center justify-between'>
        <div className='flex items-center space-x-2'>
          {icon ?? (
            <Circle className='size-4 transition-transform group-hover:scale-110' />
          )}
          <span className='text-sm font-medium'>{label}</span>
        </div>
        <div className='space-x-1'>
          <strong className='text-lg'>{count}</strong>
          {total !== count && (
            <span className='text-sm text-muted-fg'>/ {total}</span>
          )}
        </div>
      </div>

      <div className='mt-2 flex items-center space-x-2'>
        <Progress
          value={count}
          // This ensures that the progress bar IS empty when there are no counts
          max={total === count && total === 0 ? 100 : total}
          variant={variant}
          size='xs'
        />
        <span className='text-xs text-muted-fg'>{Math.round(percentage)}%</span>
      </div>

      {filteredSubItems && filteredSubItems.length > 0 && (
        <>
          <div className='my-3' />
          <div className='grid grid-cols-1 gap-4 text-xs text-muted-fg 2xl:grid-cols-2'>
            {filteredSubItems.map(({ status, count: subCount }) => (
              <div key={status} className='space-y-2'>
                <div className='flex items-center justify-between'>
                  <span>{t(`enums:documentStatus.${status}`)}</span>
                  <div className='flex items-center space-x-1'>
                    <strong>{subCount}</strong>
                    <span className='text-[10px]'>
                      ({Math.round((subCount / count) * 100)}%)
                    </span>
                  </div>
                </div>

                <Progress
                  className='h-1'
                  value={subCount}
                  max={count}
                  variant={variant}
                  size='xs'
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
});
