import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from '@/store/store';

/**
 * Hook for getting and setting the language.
 */
export function useLanguage() {
  const { language, setLanguage } = useBoundStore(
    useShallow(state => ({
      language: state.language,
      setLanguage: state.setLanguage,
    })),
  );

  return { language, setLanguage };
}
