import { formatUtils, type FindOneParams } from '@kanbu/shared';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { OperatorForm } from '@/features/operators/OperatorForm';
import { trpc } from '@/services/trpc';

const requestParams: Omit<FindOneParams, 'id'> = {
  populate: ['chat'],
  fields: ['*', 'chat.name', 'chat.id'],
};

export const Route = createFileRoute(
  '/_baseLayout/chats/$chatId/operators/$operatorId/edit',
)({
  component: OperatorsEditPage,
  loader: async ({ context: { trpcUtils }, params: { operatorId, chatId } }) =>
    Promise.all([
      trpcUtils.operators.findOne.ensureData({
        id: operatorId,
        ...requestParams,
      }),
      trpcUtils.chats.findOne.ensureData({ id: chatId }),
    ]),
});

export function OperatorsEditPage() {
  const { operatorId, chatId } = Route.useParams();
  const { t } = useTranslation(['glossary', 'operators', 'globals']);
  const [chatData] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });
  const [data] = trpc.operators.findOne.useSuspenseQuery({
    id: operatorId,
    ...requestParams,
  });

  return (
    <FormPage
      title={`${t('operators:texts.edit')} - ${formatUtils.fullName(data)}`}
      breadcrumbs={[
        {
          label: chatData.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t('globals:routeNames.operators'),
          to: '/chats/$chatId/operators',
          params: { chatId },
        },
        {
          label: formatUtils.fullName(data),
          to: '/chats/$chatId/operators/$operatorId/edit',
          params: { chatId, operatorId },
        },
      ]}
    >
      <OperatorForm chatId={chatId} data={data} />
    </FormPage>
  );
}
