import type { Options, KyInstance } from '@toss/ky';
import type { z, ZodTypeAny } from 'zod';

type HttpMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'PATCH'
  | 'OPTIONS'
  | 'TRACE';

// Extends the Ky Options type to include a raw option
export type ExtendedOptions = Options & {
  raw?: boolean;
  parseOutput?: boolean;
  useFormData?: boolean;
};

/**
 * Api Endpoint definition
 */
interface ApiEndpoint<
  I extends z.ZodTypeAny = z.ZodVoid,
  O extends z.ZodTypeAny = z.ZodVoid,
> {
  method?: HttpMethod;
  path: string;
  input?: I;
  output?: O;
  options?: ExtendedOptions; // Add this line
}

/**
 * Defines the API structure, allowing for nested definitions.
 */
export type ApiDefinition = {
  [key: string]: ApiEndpoint<z.ZodTypeAny, z.ZodTypeAny> | ApiDefinition;
};

const METHODS_WITHOUT_BODY = ['GET', 'HEAD'] as const;
type MethodWithoutBody = (typeof METHODS_WITHOUT_BODY)[number];

/**
 * Helper function to check if a method does not require a body
 */
function isMethodWithoutBody(method: string): method is MethodWithoutBody {
  return METHODS_WITHOUT_BODY.includes(method as MethodWithoutBody);
}

/**
 * Typed Client Factory Args
 */
interface TypedClientFactoryArgs<T extends ApiDefinition> {
  client: KyInstance;
  apiDefinition: T;
  options?: {
    raw?: boolean;
    parseOutput?: boolean;
    debug?: boolean;
  };
}

// Function to check if a value is an ApiEndpoint
function isApiEndpoint(value: any): value is ApiEndpoint {
  return value && typeof value === 'object' && 'path' in value;
}

// Function to check if a value is an ApiDefinition
function isApiDefinition(
  value: ApiDefinition | ApiEndpoint<ZodTypeAny, ZodTypeAny>,
): value is ApiDefinition {
  return typeof value === 'object' && !('input' in value);
}

// Type for the nested client structure
export type TypedKyClient<T extends ApiDefinition> = {
  [K in keyof T]: T[K] extends ApiEndpoint<infer I, infer O>
    ? (
        input?: I extends z.ZodTypeAny ? z.input<I> : void,
        options?: ExtendedOptions,
      ) => Promise<O extends z.ZodTypeAny ? z.output<O> : any>
    : T[K] extends ApiDefinition
      ? TypedKyClient<T[K]>
      : never;
};

// Function to create a nested client structure
function createNestedClient<T extends ApiDefinition>(
  client: KyInstance,
  apiDefinition: T,
  baseOptions: TypedClientFactoryArgs<T>['options'],
): TypedKyClient<T> {
  return Object.fromEntries(
    Object.entries(apiDefinition).map(([key, value]) => {
      // Handle api endpoint definition
      if (isApiEndpoint(value)) {
        return [
          key,
          async (input: any, options?: ExtendedOptions) => {
            const method = (
              value.method ?? 'GET'
            ).toLowerCase() as Lowercase<HttpMethod>;
            let validatedInput: any;

            if (value.input) {
              try {
                validatedInput = value.input.parse(input);
              } catch (error) {
                throw new Error(`Invalid input: ${(error as Error).message}`);
              }
            }

            let path = value.path;
            const searchParams: Record<string, string> = {};
            const bodyParams = validatedInput ? { ...validatedInput } : {};

            // Dynamically replace path parameters with input values and remove them from body
            if (validatedInput && typeof validatedInput === 'object') {
              Object.entries(validatedInput).forEach(([key, val]) => {
                const paramRegex = new RegExp(`:${key}`, 'g');

                if (paramRegex.test(path)) {
                  path = path.replace(
                    paramRegex,
                    encodeURIComponent(String(val)),
                  );
                  delete bodyParams[key];
                } else if (isMethodWithoutBody(value.method ?? 'GET')) {
                  searchParams[key] = String(val);
                }
              });
            }

            // Prepare request options based on method type
            const requestOptions: Options = {
              ...options,
              searchParams: isMethodWithoutBody(value.method ?? 'GET')
                ? searchParams
                : undefined,
            };

            // Handle request body based on method and options
            if (!isMethodWithoutBody(value.method ?? 'GET') && bodyParams) {
              if (value.options?.useFormData) {
                const formData = new FormData();

                Object.entries(bodyParams).forEach(([key, val]) => {
                  formData.append(key, val as string | Blob);
                });

                requestOptions.body = formData;
              } else {
                requestOptions.json = bodyParams;
              }
            }

            // Determine whether to return raw response
            const raw = options?.raw;

            if (baseOptions?.debug) {
              console.debug(
                `[kyClient](request) ${method.toUpperCase()} | ${path} |`,
                'requestOptions:\n',
                requestOptions,
                '\nvalidatedInput:\n',
                bodyParams,
              );
            }

            // @ts-expect-error this can't be typed properly
            const response = await client[method](path, requestOptions);

            // Return raw response if the option is set
            if (raw) {
              return response;
            }

            if (response.ok && response.headers.get('content-length') === '0') {
              return {};
            }

            const data = await response.json();

            // Parse output if required
            if (options?.parseOutput && value.output) {
              try {
                return value.output.parse(data);
              } catch (error) {
                throw new Error(`Invalid output: ${(error as Error).message}`);
              }
            }

            return data;
          },
        ];
      }

      // This is nested route, continue building the client deeper
      return [
        key,
        isApiDefinition(value)
          ? createNestedClient(client, value, baseOptions)
          : value,
      ];
    }),
  ) as TypedKyClient<T>;
}

/**
 * Creates a typed client for the given API definition.
 *
 * @example
 * const client = createTypedKyClient({
 *   client: create({ prefixUrl: 'https://api.example.com' }),
 *   apiDefinition: {
 *     auth: {
 *       login: {
 *         method: 'POST',
 *         path: '/login',
 *         input: z.object({
 *           username: z.string(),
 *           password: z.string(),
 *         }),
 *         output: z.object({
 *           token: z.string(),
 *         }),
 *       },
 *     },
 *     users: {
 *       get: {
 *         method: 'GET',
 *         path: '/users/:id',
 *         input: z.object({
 *           id: z.string(),
 *         }),
 *         output: z.object({
 *           id: z.string(),
 *           name: z.string(),
 *         }),
 *       },
 *     },
 *   },
 * });
 *
 * const token = await client.auth.login({ username: 'user', password: 'pass' });
 * const user = await client.users.get({ id: '123' });
 */
export const createTypedKyClient = <T extends ApiDefinition>({
  client,
  apiDefinition,
  options = {},
}: TypedClientFactoryArgs<T>): TypedKyClient<T> => {
  return createNestedClient(client, apiDefinition, options);
};
