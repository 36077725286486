import { z } from 'zod';

export const efClubSchema = z.object({
  name: z.string(),
  street: z.string(),
  postalCode: z.string(),
  city: z.string(),
  phoneNumber: z.string().nullable(),
  headquarterPhoneNumber: z.string().nullable(),
  faxNumber: z.string().nullable(),
  email: z.string().nullable(),
  companyName: z.string(),
  companyStreet: z.string(),
  companyPostalCode: z.string(),
  companyCity: z.string(),
  companyPhoneNumber: z.string().nullable(),
  companyFaxNumber: z.string().nullable(),
  companyEmail: z.string().nullable(),
  chainId: z.number(),
  clubId: z.number(),
  currency: z.string(),
  currencyCode: z.string(),
  mobileClubName: z.string().nullable(),
  mobileClubDescription: z.string().nullable(),
  mobileClubOpeningHours: z.string().nullable(),
  mobileClubBackgroundPhoto: z.string().nullable(),
  mobileClubLogo: z.string().nullable(),
  mobileClubUrl: z.string().nullable(),
  mobileClubApplications: z.array(z.unknown()),
  mobileClubStyle: z.record(z.unknown()),
  isQrCodeOn: z.boolean(),
  feedbackMail: z.string().nullable(),
  isVersionZero: z.boolean(),
  isPaidApplication: z.boolean(),
  latitude: z.string().nullable(),
  longitude: z.string().nullable(),
});

export const efClubsResponseSchema = z.object({
  results: z.array(efClubSchema),
});

export type EfClubDTO = z.infer<typeof efClubSchema>;
