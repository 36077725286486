import type { ChatMessageType } from '@kanbu/schema';
import { type RagResultToolDef, ToolNames } from '@kanbu/schema/contracts';
import { ChatRole, ToolType } from '@kanbu/schema/enums';
import { DateFormat, formatUtils, renderMarkdown } from '@kanbu/shared';
import { cn } from '@utima/ui';
import { memo } from 'react';

import Feedback from '@/components/chat/Feedback.tsx';

export type MessageProps = {
  message: ChatMessageType;
};

type MessageContent = {
  messages: string[];
};

// TODO: Whole ChatMessage data structure should be refactored to reduce complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
function getMessageContent(message: ChatMessageType): MessageContent {
  try {
    if (message?.content) {
      return { messages: [message.content] };
    }

    if (!message?.toolCalls) {
      return { messages: [] };
    }

    const contentAggregator: string[] = [];

    for (const tool of message.toolCalls) {
      if (tool.type === ToolType.Text) {
        contentAggregator.push(tool?.content);
        continue;
      }

      if (tool.type === ToolType.Tool && tool.name === ToolNames.RagResult) {
        if (tool.args.content) {
          contentAggregator.push(tool.args.content);
        }

        if (tool.args?.sources?.length) {
          const sourceList: string = tool.args.sources
            .map(
              (source: Required<RagResultToolDef>['sources'][number]) =>
                `- [${source.fileName}](${source.url})`,
            )
            .join('\n');
          contentAggregator.push(sourceList);
        }
        continue;
      }

      // Generic tool call
      if (tool?.args && 'content' in tool?.args) {
        const { content: messageContent, ...restArgs } = tool?.args ?? {};

        contentAggregator.push(
          messageContent + '\n\n' + JSON.stringify(restArgs, null, 2),
        );
      }
    }

    return { messages: contentAggregator ?? '' };
  } catch {
    return {
      messages: ['Unknown error occurred while parsing message content'],
    };
  }
}

export const Message = memo(function Message({ message }: MessageProps) {
  const { role, createdAt, feedback } = message;
  const parsedMessage = getMessageContent(message);

  return (
    <div className={cn('flex flex-col gap-1')}>
      {parsedMessage.messages.map((content, index) => (
        <div
          key={index}
          className={cn(
            'flex max-w-[90%] rounded-2xl px-3.5 py-3 text-darkGray',
            {
              ['rounded-br-none ml-auto bg-secondary']: role === ChatRole.User,
              ['rounded-bl-none bg-gray-200']: role === ChatRole.Assistant,
            },
          )}
        >
          <div
            className='markdown-wrapper -mb-4 text-sm'
            dangerouslySetInnerHTML={{
              __html: renderMarkdown(content),
            }}
          />
        </div>
      ))}
      {feedback && <Feedback feedback={feedback} />}

      <div
        className={cn('text-xs mt-1', {
          ['text-right']: role === ChatRole.User,
          ['text-left']: role === ChatRole.Assistant,
        })}
      >
        {formatUtils.date(createdAt, DateFormat.DateTime)}
      </div>
    </div>
  );
});
