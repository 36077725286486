import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { FAQForm } from '@/features/faqs/FAQForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chats/$chatId/faqs/create')({
  component: FAQsCreatePage,
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId }),
});

export function FAQsCreatePage() {
  const { chatId } = Route.useParams();
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });
  const { t } = useTranslation(['globals', 'faqs']);

  return (
    <FormPage
      title={t('faqs:texts.create')}
      breadcrumbs={[
        {
          label: data.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t('globals:routeNames.faqs'),
          to: '/chats/$chatId/faqs',
          params: { chatId },
        },
        { label: t('faqs:texts.create') },
      ]}
    >
      <FAQForm chatId={chatId} />
    </FormPage>
  );
}
