import { trpc } from '@/services/trpc';

export function useChatMutation() {
  const utils = trpc.useUtils();

  const create = trpc.chats.create.useMutation({
    onSuccess: async () => utils.chats.findAll.invalidate(),
  });

  const duplicate = trpc.chats.duplicate.useMutation({
    onSuccess: async () => utils.chats.findAll.invalidate(),
  });

  const update = trpc.chats.update.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.chats.findAll.invalidate(),
        utils.chats.findOne.invalidate({ id }),
      ]),
  });

  const remove = trpc.chats.delete.useMutation({
    onSuccess: async (data, { id }) => utils.chats.findAll.invalidate(),
  });

  return {
    duplicate,
    create,
    update,
    remove,
  };
}
