import type { ChatType } from '@kanbu/schema';
import { DocumentStatus } from '@kanbu/schema/enums';
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Button, Tooltip } from '@utima/ui';
import { memo } from 'react';

import { useDocumentMutation } from '@/features/documents';

export type RetryFailedButtonProps = {
  chat: ChatType;
};

export const RetryFailedButton = memo(function RetryFailedButton({
  chat,
}: RetryFailedButtonProps) {
  const { refreshAll } = useDocumentMutation();

  const handleRetry = async () => {
    await refreshAll.mutateAsync({
      chatId: chat.id,
      statuses: [DocumentStatus.EmbeddingError, DocumentStatus.ParsingError],
    });
  };

  if (
    Number(chat.parsingErrorDocumentsCount) === 0 &&
    Number(chat.embeddingErrorDocumentsCount) === 0
  ) {
    return null;
  }

  return (
    <Tooltip title={t`Retry failed documents`}>
      <Button
        size='sm'
        variant='danger'
        loading={refreshAll.isPending}
        disabled={refreshAll.isPending}
        onClick={handleRetry}
      >
        <Trans>Retry Failed</Trans>
      </Button>
    </Tooltip>
  );
});
