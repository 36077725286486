import type { FAQType } from '@kanbu/schema';
import { createFAQSchema, updateFAQSchema } from '@kanbu/schema/contracts';
import { createSearchExpression } from '@kanbu/schema/shared';
import { getQueryKey } from '@trpc/react-query';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import type { DataProviderFactory } from '@/components/combobox/Combobox.tsx';
import { ComboboxControl } from '@/components/combobox/ComboboxControl.tsx';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { trpc, trpcClient } from '@/services/trpc.ts';
import { useBoundStore } from '@/store/store.ts';

import { useFAQMutation } from './useFAQMutation';

export type FAQFormProps = {
  data?: FAQType;
  chatId: string;
};

export const FAQForm = memo(function FAQForm({ data, chatId }: FAQFormProps) {
  const { t } = useTranslation(['glossary']);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useFAQMutation();
  const { chat: currentChat } = useBoundStore(state => ({
    chat: state.chat,
  }));

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'title',
        label: t('glossary:labels.name'),
        required: true,
      },
      {
        name: 'question',
        label: t('glossary:labels.question'),
        required: true,
      },
      {
        name: 'answer',
        label: t('glossary:labels.answer'),
        required: true,
      },
    ],
    [t],
  );

  const faqFormDataProviderFactory = ({
    excludeIds,
    currentChatId,
  }: {
    excludeIds?: string[];
    currentChatId?: string;
  }) => {
    const dataProvider: DataProviderFactory<FAQType, string> = () => ({
      query: async ({ pageSize, search }) => {
        const where = {
          id: { $nin: excludeIds || undefined },
          searchable: {
            $like: search ? createSearchExpression(search) : undefined,
          },
          chat_id: currentChatId || undefined,
        };

        const data = await trpcClient.faqs.findAll.query({
          pagination: {
            pageSize,
          },
          orderBy: { title: 'asc' },
          where,
        });

        return {
          items: data.items,
          total: data.meta.total,
        };
      },

      cacheKeyFactory: params => [
        ...getQueryKey(trpc.faqs.findAll),
        { orderBy: { name: 'asc' } },
        params,
        data?.id,
      ],
    });

    return dataProvider();
  };

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createFAQSchema.omit({ chat: true })}
      updateSchema={updateFAQSchema.omit({ chat: true })}
      onCreate={values => create({ ...values, chat: chatId })}
      onUpdate={update}
      onRemove={remove}
      basePath={`/chats/${chatId}/faqs`}
      idParamName='faqId'
    >
      <EntityCRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <ComboboxControl
            name='parent'
            label={t('glossary:labels.parent')}
            initialValue={data?.parent?.id}
            renderDisplayValue={item => item?.title}
            shouldFilter={false}
            renderSearchValue={item => item?.title}
            dataProviderFactory={() =>
              faqFormDataProviderFactory({
                excludeIds: data?.id ? [data.id] : [],
                currentChatId: currentChat?.id,
              })
            }
          />
          <SchemaFields schema={detailsSchema} />
        </Card>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col />
      <EntityCRUDForm.Col>
        <MetaFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
