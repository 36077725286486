import { z } from 'zod';

import { ContentType } from '../enums';

export const ToolNames = {
  RagResult: 'rag_result',
  Membership: 'membership',
  Authentication: 'authentication',
  MembershipStatus: 'membership_status',
  MembershipPause: 'membership_pause',
  UserProfile: 'user_profile',
  RagResultOperator: 'rag_result_operator',
} as const;

export const ragResultToolDef = z.object({
  content: z
    .string()
    .describe(
      'Main response with factual information from documents, formatted in markdown',
    ),
  sources: z
    .array(
      z.object({
        type: z.nativeEnum(ContentType).describe('Source document type'),
        url: z.string().describe('Document URL from metadata'),
        fileName: z
          .string()
          .describe(
            'Prettified source document file name from metadata. Remove underscores and capitalize. Drop file extensions.',
          ),
        page: z
          .string()
          .describe(
            'Number of page in the document on which the information was found. The page number is stored in the metadata.',
          ),
      }),
    )
    .optional()
    .describe('List of source documents referenced in the response'),
  answered: z.boolean().describe('Whether the question was answered'),
});

export const ragResultToolOperator = z.object({
  content: z
    .string()
    .describe(
      'natural conversationally response to the user, use the chat history to form the response',
    ),
  operator: z
    .object({
      name: z.string().describe('Operator name'),
      email: z.string().describe('Operator email'),
      phone: z.string().describe('Operator phone'),
    })
    .optional()
    .describe('Operator contact information'),
});

export type RagResultToolDef = z.infer<typeof ragResultToolDef>;
export type RagResultToolOperator = z.infer<typeof ragResultToolOperator>;

export const authenticationToolDef = z
  .object({
    content: z
      .string()
      .describe(
        'Natural conversational response. Format using markdown when appropriate.',
      ),
    shouldLogin: z.boolean().describe('Whether the user should login'),
    shouldRegister: z
      .boolean()
      .describe('Whether the user should be prompted to register'),
  })
  .describe('Membership prompt for logging in or registration');

export type AuthenticationToolDef = z.infer<typeof authenticationToolDef>;

export const userProfileToolDef = z
  .object({
    content: z
      .string()
      .describe(
        'Natural conversational response about user profile. Format using markdown when appropriate.',
      ),
    firstName: z.string().describe('User first name'),
    lastName: z.string().describe('User last name'),
    email: z.string().describe('User email'),
    phone: z.string().optional().describe('User phone number'),
    gender: z
      .enum(['man', 'woman', 'other'])
      .optional()
      .describe('User gender'),
    birthDate: z.string().optional().describe('User birth date'),
    address: z.string().optional().describe('User address'),
    isEmailVerified: z.boolean().describe('Whether the email is verified'),
  })
  .describe('User profile information parsed from member_data');

export type UserProfileToolDef = z.infer<typeof userProfileToolDef>;

export const membershipPauseToolDef = z.object({
  content: z
    .string()
    .describe(
      'Natural conversational response about user profile. Format using markdown when appropriate.',
    ),
  showPauseMembershipButton: z
    .boolean()
    .describe(
      'Whether to show pause membership button. Show when: 1) User explicitly asks to pause an ongoing membership.',
    ),
});

export type MembershipPauseToolDef = z.infer<typeof membershipPauseToolDef>;

export const membershipStatusToolDef = z
  .object({
    content: z
      .string()
      .describe(
        'Natural conversational response. Format using markdown when appropriate.',
      ),
    activeMembership: z
      .string()
      .optional()
      .describe(
        'Name of the active membership plan. Should be exactly as stored in the system.',
      ),
    activeFrom: z
      .string()
      .describe('Membership start date in ISO format (YYYY-MM-DD).')
      .optional(),
    activeTo: z
      .string()
      .describe('Membership end date in ISO format (YYYY-MM-DD).')
      .optional(),
    isExpired: z
      .boolean()
      .default(true)
      .describe('Whether the membership is expired'),
    showNewMembershipButton: z
      .boolean()
      .default(false)
      .describe(
        'Whether to show new membership button. Show when: 1) User explicitly asks about new membership, 2) When membership_data is missing, 3) User asks about extending membership.',
      ),
    // TODO: This is here to disable the pauseMembership functionality
    showPauseMembershipButton: z
      .boolean()
      .default(false)
      .describe(
        'Whether to show pause membership button. Show when: 1) User explicitly asks to pause an ongoing membership.',
      ),
  })
  .describe(
    'Current membership status, when type is membership_status, even when membership data are empty',
  );

export type MembershipStatusToolDef = z.infer<typeof membershipStatusToolDef>;

/**
 * Tool definition for eFitness membership related questions.
 * We define multiple output structures, which we use
 * based on the LLM response type.
 */
export const membershipToolDef = z.object({
  type: z
    .enum([
      'membership_status',
      'user_profile',
      'membership_registration',
      'membership_login',
      'other',
    ])
    .describe('Type of response based on user query intent'),
  content: z
    .string()
    .describe(
      'Natural conversational response. Format using markdown when appropriate.',
    ),
  // Tool for showing current membership status
  membership_status: z
    .object({
      activeMembership: z
        .string()
        .optional()
        .describe(
          'Name of the active membership plan. Should be exactly as stored in the system.',
        ),
      activeFrom: z
        .string()
        .describe('Membership start date in ISO format (YYYY-MM-DD).')
        .optional(),
      activeTo: z
        .string()
        .describe('Membership end date in ISO format (YYYY-MM-DD).')
        .optional(),
      isExpired: z.boolean().describe('Whether the membership is expired'),
      showNewMembershipButton: z
        .boolean()
        .describe(
          'Whether to show new membership button. Show when: 1) User explicitly asks about new membership, 2) Current membership is expired, 3) User asks about extending membership.',
        ),
      showPauseMembershipButton: z
        .boolean()
        .describe(
          'Whether to show pause membership button. Show when: 1) User explicitly asks to pause an ongoing membership.',
        ),
    })
    .optional()
    .describe(
      'Current membership status, when type is membership_status, even when membership data are empty',
    ),
  // Tool for showing user profile information
  user_profile: z
    .object({
      firstName: z.string().describe('User first name'),
      lastName: z.string().describe('User last name'),
      email: z.string().describe('User email'),
      phone: z.string().optional().describe('User phone number'),
      gender: z
        .enum(['man', 'woman', 'other'])
        .optional()
        .describe('User gender'),
      birthDate: z.string().optional().describe('User birth date'),
      address: z.string().optional().describe('User address'),
      isEmailVerified: z.boolean().describe('Whether the email is verified'),
    })
    .optional()
    .describe('User profile information, when type is user_profile'),

  // Tool for membership registration prompt
  membership_registration: z
    .object({
      shouldRegister: z
        .boolean()
        .describe('Whether the user should be prompted to register'),
    })
    .optional()
    .describe(
      'Membership registration prompt, when type is membership_registration',
    ),
  // Tool for login prompt
  membership_login: z
    .object({
      shouldLogin: z.boolean().describe('Whether the user should login'),
    })
    .optional()
    .describe('Membership login prompt, when type is membership_login'),

  // Tool for other topics
  other: z
    .object({
      type: z.literal('other'),
    })
    .optional(),
});

export type MembershipToolDef = z.infer<typeof membershipToolDef>;
