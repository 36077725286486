import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';

export const operatorSchema = baseOrganizationEntitySchema.extend({
  email: z.string().email().max(320),
  phone: z.string().max(15).nullish(),
  firstName: z.string().max(70),
  lastName: z.string().max(70),
  active: z.boolean(),
  chat: z.string().uuid(),
});

export const createOperatorSchema = operatorSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    organization: true,
  })
  .extend({
    active: z.boolean(),
  })
  .strict();

export const updateOperatorSchema = operatorSchema
  .partial()
  .required({
    id: true,
  })
  .omit({
    organization: true,
  })
  .strict();

export type OperatorDTO = z.infer<typeof operatorSchema>;
export type CreateOperatorDTO = z.infer<typeof createOperatorSchema>;
export type UpdateOperatorDTO = z.infer<typeof updateOperatorSchema>;
