import { i18n } from '@lingui/core';

export const defaultLocale = 'cs';
export const supportedUILocales = ['en', 'cs'];

export type SupportedUILocale = (typeof supportedUILocales)[number];

/**
 * Dynamically load localization for the given locale.
 */
export async function dynamicActivate(locale: string) {
  try {
    // Try to load the locale
    const { messages } = await import(`./locales/${locale}.po`);

    i18n.load(locale, messages);
    i18n.activate(locale);
  } catch (error) {
    console.error(`Failed to load locale: ${locale}`, error);

    // Fallback to default locale if not already trying to load it
    if (locale !== defaultLocale) {
      const { messages } = await import(`./locales/${defaultLocale}.po`);

      i18n.load(defaultLocale, messages);
      i18n.activate(defaultLocale);
    }
  }
}
