/**
 * Checks if the given item is an object.
 *
 * @example
 * const obj = { a: 1, b: 2 };
 * const isObj = isObject(obj);
 *
 * -> isObj will be true
 */
function isObject(item: unknown): item is Record<string, any> {
  return typeof item === 'object' && item !== null && !Array.isArray(item);
}

/**
 * Deep merges two objects.
 *
 * @example
 * const obj1 = { a: 1, b: { c: 2, d: 3 } };
 * const obj2 = { b: { c: 4, e: 5 }, f: 6 };
 * const merged = deepMerge(obj1, obj2);
 *
 * -> merged will be { a: 1, b: { c: 4, d: 3, e: 5 }, f: 6 }
 */
export function deepMerge<T extends Record<string, any>>(
  target: T,
  source: Partial<T>,
): T {
  const merged = structuredClone(target);
  for (const key in source) {
    if (source[key] !== undefined) {
      merged[key] =
        isObject(merged[key]) && isObject(source[key])
          ? deepMerge(merged[key], source[key])
          : structuredClone(source[key]);
    }
  }

  return merged;
}

/**
 * Converts a PascalCase string to a kebab-case string.
 *
 * @example
 * const str = 'MyVariableName';
 * const kebab = pascalToKebab(str);
 *
 * -> kebab will be 'my-variable-name'
 */
export function pascalToKebab(str: string) {
  return str.replaceAll(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

const NANO_ID_CHARS =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

/**
 * Generates a random ID with 8 characters. In following format:
 * '2k4j3h7k', you can customize the length by passing a different number.
 */
export function nanoId(length = 8) {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += NANO_ID_CHARS[Math.floor(Math.random() * NANO_ID_CHARS.length)];
  }

  return result;
}

/**
 * Chunks an array into smaller arrays of a given size.
 */
export function chunkArray<T>(arr: T[], size: number): T[][] {
  return arr.reduce<T[][]>(
    (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
    [],
  );
}

/**
 * Extracts the file extension from a URI. The output includes
 * leading dot, e.g. `.txt`, `.md`, etc.
 */
export function extractFileExtension(uri: string): string | null {
  const extension = uri.split('.').pop();

  if (!extension) {
    return null;
  }

  return `.${extension}`;
}

/**
 * Converts http and https URLs to ws and wss URLs.
 * The path is preserved. It is usually used to generate
 * websocket endpoints from base API urls.
 */
export function getWsUrlFromHttpUrl(url: string) {
  const parsedUrl = new URL(url);

  return `${parsedUrl.protocol === 'https:' ? 'wss' : 'ws'}://${parsedUrl.host}${parsedUrl.pathname}`;
}
