import { toast } from '@utima/ui';
import { Form, type FormProps, type TypedFormState } from '@utima/ui-informed';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppSettings } from '@/constants/AppSettings';

import { ActionBar } from './ActionBar';
import { FormCol } from './FormCol';
import { FormGrid } from './FormGrid';
import { defaultAdapterContext, FormSchemaContext } from './FormSchemaContext';
import { UIFormDevtools } from './UIFormDevtools';
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';

export interface CRUDFormProps<T> extends FormProps<T> {
  columns?: boolean;
  onDelete?: () => Promise<unknown>;
  showDelete?: boolean;
}

/**
 * Wrapper around Form component, which provides CRUD actions and
 * confirmation dialogs for delete action. It also handles success
 * and failure notifications and provides 3-column layout.
 */
export function CRUDForm<T>({
  onDelete,
  onSubmit,
  children,
  readOnly,
  disabled = false,
  loading = false,
  columns = true,
  initialValues,
  showDelete = true,
  ...restProps
}: CRUDFormProps<T>) {
  const { t } = useTranslation(['zod', 'globals']);
  const [dialogOpen, setDialogOpen] = useState(false);

  /**
   * Default submit handler, handles error and success notifications.
   */
  const handleSubmit = useCallback(
    async (formState: TypedFormState<T>) => {
      try {
        await onSubmit?.(formState);

        // Show success notification
        toast.success(t('globals:crudForm.success.title'), {
          description: t('globals:crudForm.success.message'),
        });
      } catch (error) {
        console.error(error);
        if (AppSettings.debug) {
          toast.error(t('globals:crudForm.invalid.title'), {
            description: () => (
              <div>
                {t('globals:crudForm.invalid.message')}
                <pre className='mt-2 overflow-auto rounded-radius bg-slate-100 p-2 font-mono text-[11px]'>
                  {JSON.stringify(error, null, 2)}
                </pre>
              </div>
            ),
          });
        } else {
          toast.error(t('globals:crudForm.invalid.title'), {
            description: t('globals:crudForm.invalid.message'),
          });
        }
      }
    },
    [onSubmit, t],
  );

  /**
   * Default failure handler, shows validation error notification.
   */
  const handleSubmitFailure = useCallback(() => {
    toast.error(t('globals:crudForm.invalid.title'), {
      description: t('globals:crudForm.invalid.message'),
    });
  }, [t]);

  /**
   * Delete handler, opens confirmation modal and calls onDelete callback.
   */
  const handleDelete = useCallback(() => {
    setDialogOpen(true);
  }, []);

  return (
    <FormSchemaContext.Provider value={defaultAdapterContext}>
      <Form
        showOptional
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitFailure={handleSubmitFailure}
        readOnly={readOnly}
        {...restProps}
      >
        {columns ? <FormGrid>{children}</FormGrid> : children}

        {!readOnly && (
          <ActionBar
            showDelete={!!initialValues && showDelete}
            onDelete={handleDelete}
          />
        )}

        <ConfirmDialog
          onConfirm={async () => onDelete?.()}
          open={dialogOpen}
          onOpenChange={setDialogOpen}
        />

        <UIFormDevtools />
      </Form>
    </FormSchemaContext.Provider>
  );
}

// Shortcut for FormCol component
CRUDForm.Col = FormCol;
