import { AgentType, ChatPlacementPosition } from '@kanbu/schema/enums';
import { useFieldState } from 'informed';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export const ChatPlacementFields = memo(function ChatPlacementFields() {
  const { t } = useTranslation(['chats', 'enums']);
  const typeFieldState = useFieldState('agentType');

  const { schema: detailsSchema } = useFieldsSchema([
    {
      name: 'chatbotConfig.placement.position',
      label: t('chats:labels.position'),
      type: 'select',
      options: optionsFromEnum(ChatPlacementPosition, key =>
        t(`enums:chatPlacementPosition.${key}`),
      ),
      defaultValue: ChatPlacementPosition.Right,
      showOptional: false,
    },
    {
      name: 'chatbotConfig.placement.offsetX',
      type: 'number',
      label: t('chats:labels.offsetX'),
      defaultValue: 0,
      showOptional: false,
      uiProps: {
        addonAfter: 'px',
      },
    },
    {
      name: 'chatbotConfig.placement.offsetY',
      type: 'number',
      label: t('chats:labels.offsetY'),
      defaultValue: 0,
      showOptional: false,
      uiProps: {
        addonAfter: 'px',
      },
    },
  ]);

  // These fields should be visible only if the agent type is Chat
  if (typeFieldState.value !== AgentType.Chat) {
    return null;
  }

  return (
    <Card title={t('chats:labels.placement')}>
      <SchemaFields schema={detailsSchema} />
    </Card>
  );
});
