import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { FAQForm } from '@/features/faqs/FAQForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute(
  '/_baseLayout/chats/$chatId/faqs/$faqId/edit',
)({
  component: FAQsEditPage,
  loader: async ({ context: { trpcUtils }, params: { faqId, chatId } }) =>
    Promise.all([
      trpcUtils.faqs.findOne.ensureData({ id: faqId, populate: ['parent'] }),
      trpcUtils.chats.findOne.ensureData({ id: chatId }),
    ]),
});

export function FAQsEditPage() {
  const { faqId, chatId } = Route.useParams();
  const [chatData] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });
  const { t } = useTranslation(['glossary', 'globals', 'faqs']);
  const [data] = trpc.faqs.findOne.useSuspenseQuery({
    id: faqId,
    populate: ['parent'],
  });

  return (
    <FormPage
      title={`${t('faqs:texts.edit')} - ${data.title}`}
      breadcrumbs={[
        {
          label: chatData.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t('globals:routeNames.faqs'),
          to: '/chats/$chatId/faqs',
          params: { chatId },
        },
        {
          label: data.title,
          to: '/faqs/$faqId/edit',
          params: { faqId },
        },
      ]}
    >
      <FAQForm data={data} chatId={chatId} />
    </FormPage>
  );
}
