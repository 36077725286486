import { Role } from '@kanbu/schema/enums';

import { useBoundStore } from '@/store/store';

import { ForbiddenError } from './errors';

/**
 * Guard that checks if the user is authorized to access the route.
 * If the user is not authorized, it throws a ForbiddenError.
 */
export function accessGuard(roles: Role[], organizationId?: string) {
  const { user } = useBoundStore.getState();

  if (!user) {
    throw new ForbiddenError('User is not available.');
  }

  if (!roles.includes(user.role)) {
    throw new ForbiddenError('User is not authorized.');
  }

  if (
    user.role !== Role.SuperAdmin &&
    organizationId &&
    user.organization?.id !== organizationId
  ) {
    throw new ForbiddenError('User is not authorized.');
  }
}
