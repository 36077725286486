import type { DocumentStatus } from '@kanbu/schema/enums';
import { CircleCheck, CircleDashed, CircleX } from 'lucide-react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentsStatusItem } from './DocumentsStatusItem';

export type StatisticItem = {
  status: DocumentStatus;
  count: number;
  isError?: boolean;
  isProcessing?: boolean;
  isDone?: boolean;
};

export type DocumentsStatusSummaryProps = {
  title: string;
  total: number;
  statistics: StatisticItem[];
};

export const DocumentsStatusSummary = memo(function DocumentsStatusSummary({
  title,
  total,
  statistics,
}: DocumentsStatusSummaryProps) {
  const { t } = useTranslation(['chats', 'glossary']);

  const {
    inProgress,
    errored,
    done,
    errorSteps,
    progressSteps,
    progressPercentage,
    errorPercentage,
    inProgressPercentage,
  } = useMemo(() => {
    const inProgress = statistics.reduce(
      (acc, step) => (step.isProcessing ? acc + step.count : acc),
      0,
    );

    const errored = statistics.reduce(
      (acc, step) => (step.isError ? acc + step.count : acc),
      0,
    );

    const done = statistics.reduce(
      (acc, step) => (step.isDone ? acc + step.count : acc),
      0,
    );

    const errorSteps = statistics
      .filter(step => step.isError)
      .map(step => ({
        status: step.status,
        count: step.count,
      }));

    const progressSteps = statistics
      .filter(step => step.isProcessing)
      .map(step => ({
        status: step.status,
        count: step.count,
      }));

    return {
      total,
      inProgress,
      errored,
      done,
      errorSteps,
      progressSteps,
      progressPercentage: total > 0 ? (done / total) * 100 : 0,
      errorPercentage: total > 0 ? (errored / total) * 100 : 0,
      inProgressPercentage: total > 0 ? (inProgress / total) * 100 : 0,
    };
  }, [statistics, total]);

  return (
    <div className='mb-4 overflow-hidden rounded-radius border border-border bg-white'>
      <div className='relative h-1 w-full'>
        <div className='absolute inset-0 bg-muted/20' />
        <div
          className='absolute inset-y-0 bg-info/60 transition-all duration-500'
          style={{
            width: `${inProgressPercentage}%`,
          }}
        />
        <div
          className='absolute inset-y-0 bg-danger/60 transition-all duration-500'
          style={{
            width: `${errorPercentage}%`,
            left: `${inProgressPercentage}%`,
          }}
        />
        <div
          className='absolute inset-y-0 bg-success/60 transition-all duration-500'
          style={{
            width: `${progressPercentage}%`,
            left: `${errorPercentage + inProgressPercentage}%`,
          }}
        />
      </div>

      <div className='p-4'>
        <div className='mb-4 flex items-center justify-between'>
          <h4 className='text-base font-bold'>{title}</h4>
          <div className='text-xs text-muted-fg'>
            {Math.round(progressPercentage + errorPercentage)}%{' '}
            {t('glossary:labels.completed')}
          </div>
        </div>

        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
          <DocumentsStatusItem
            label={t('glossary:labels.processing')}
            count={inProgress}
            total={total - done - errored}
            variant='info'
            subItems={progressSteps}
            icon={<CircleDashed className='size-4 text-info' />}
          />

          <DocumentsStatusItem
            label={t('glossary:labels.errored')}
            count={errored}
            total={total}
            variant='danger'
            icon={<CircleX className='size-4 text-danger' />}
            subItems={errorSteps}
          />

          <DocumentsStatusItem
            label={t('glossary:labels.done')}
            count={done}
            total={total}
            variant='success'
            icon={<CircleCheck className='size-4 text-success' />}
          />
        </div>
      </div>
    </div>
  );
});
