import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import { Input, type TypedFormState } from '@utima/ui-informed';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { PasswordFields } from '@/components/fields/passwordFields/PasswordFields';
import { BasicForm } from '@/components/form/BasicForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_authLayout/verify/')({
  component: VerificationPage,
  validateSearch: (search: Record<string, unknown>): { token: string } => {
    return {
      token: (search.token as string) || '',
    };
  },
});

export function VerificationPage() {
  const { t } = useTranslation(['glossary', 'auth']);
  const navigate = useNavigate();
  const verifyMutation = trpc.auth.verify.useMutation();
  const { token } = Route.useSearch();

  let expiresAt: number = 0;
  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    consentWithToC: false,
  };

  try {
    const jwtPayload = jwtDecode(token) as JwtPayload & { email: string };
    initialValues.email = jwtPayload.email;
    expiresAt = jwtPayload.exp!;
  } catch (e) {
    console.error(e);
  }

  if (!token || !initialValues.email || expiresAt < Date.now() / 1000) {
    return (
      <div className='flex flex-col items-center gap-6'>
        <h1 className='text-center text-2xl font-bold'>
          {t('auth:texts.verification.title')}
        </h1>
        <div className='text-center text-sm'>
          {t('auth:texts.verification.invalidToken')}
        </div>
      </div>
    );
  }

  const onSubmit = (formState: TypedFormState<typeof initialValues>) => {
    verifyMutation.mutate(
      {
        token: token,
        email: formState.values.email,
        password: formState.values.password,
      },
      {
        onError: (error: unknown) => {
          console.error(error);
          toast.error(t('auth:toasts.verification.error.title'), {
            description: t('auth:toasts.verification.error.description'),
          });
        },
        onSuccess: () => {
          toast.success(t('auth:toasts.verification.success.title'), {
            description: t('auth:toasts.verification.success.description'),
          });

          navigate({ to: '/login' });
        },
      },
    );
  };

  return (
    <BasicForm
      onSubmit={onSubmit}
      className='flex w-full flex-col gap-6'
      zodSchema={z.object({
        email: z.string().email(),
        password: z.string().min(6),
        confirmPassword: z.string().min(6),
        consentWithToC: z.boolean().refine(val => val === true, {
          message: t('auth:texts.verification.consentRequired'),
        }),
      })}
      initialValues={initialValues}
    >
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:texts.verification.title')}
      </h1>
      <Input name='email' type='email' disabled required />
      <PasswordFields
        title={t('auth:texts.verification.passwordFields')}
        hasOldPassword={false}
      />
      <Button variant='primary' type='submit'>
        {t('glossary:actions.register')}
      </Button>
    </BasicForm>
  );
}
