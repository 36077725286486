import { trpc } from '@/services/trpc';

export function useDocumentMutation() {
  const utils = trpc.useUtils();

  const create = trpc.documents.create.useMutation({
    onSuccess: async () => utils.documents.findAll.invalidate(),
  });

  const update = trpc.documents.update.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.documents.findAll.invalidate(),
        utils.documents.findOne.invalidate({ id }),
      ]),
  });

  const refresh = trpc.documents.refresh.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.documents.findAll.invalidate(),
        utils.documents.findOne.invalidate({ id }),
      ]),
  });

  const resetEmbedding = trpc.documents.resetEmbedding.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.documents.findAll.invalidate(),
        utils.documents.findOne.invalidate({ id }),
      ]),
  });

  const resetAllEmbeddings = trpc.documents.resetAllEmbeddings.useMutation({
    onSuccess: async (data, { chatId }) =>
      Promise.all([
        utils.documents.findAll.invalidate(),
        utils.chats.findOne.invalidate({ id: chatId }),
      ]),
  });

  const refreshAll = trpc.documents.refreshAll.useMutation({
    onSuccess: async (data, { chatId }) =>
      Promise.all([
        utils.documents.findAll.invalidate(),
        utils.chats.findOne.invalidate({ id: chatId }),
      ]),
  });

  const remove = trpc.documents.delete.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.documents.findAll.invalidate(),
        utils.chats.findAll.invalidate(),
      ]),
  });

  return {
    create,
    update,
    remove,
    refresh,
    refreshAll,
    resetEmbedding,
    resetAllEmbeddings,
  };
}
