import { AgentType } from '@kanbu/schema/enums';
import { THEME_PRESETS, AgentDefaults } from '@kanbu/shared';
import { Button, Label, Tooltip } from '@utima/ui';
import { useFormApi } from 'informed';
import { Link, Moon, Sun } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useBoundStore } from '@/store/store';

export type ChatThemeFieldsProps = {};

export const ChatThemeFields = memo(
  function ChatThemeFields({}: ChatThemeFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums', 'chats']);
    const { setValue } = useFormApi();
    const { chat: currentChat } = useBoundStore(state => ({
      chat: state.chat,
    }));

    const { schema } = useFieldsSchema(
      [
        {
          name: 'themeConfig.primary',
          label: t('glossary:labels.primaryBg'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.primary,
        },
        {
          name: 'themeConfig.primaryFg',
          label: t('glossary:labels.primaryFg'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.primaryFg,
        },
        {
          name: 'themeConfig.secondary',
          label: t('glossary:labels.secondaryColor'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.secondary,
        },
        {
          name: 'themeConfig.secondaryFg',
          label: t('glossary:labels.secondaryFg'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.secondaryFg,
        },
        {
          name: 'themeConfig.backgroundSecondary',
          label: t('glossary:labels.backgroundSecondary'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.backgroundSecondary,
        },
        {
          name: 'themeConfig.background',
          label: t('glossary:labels.background'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.background,
        },
        {
          name: 'themeConfig.foreground',
          label: t('glossary:labels.foreground'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.foreground,
        },
        {
          name: 'themeConfig.placeholder',
          label: t('glossary:labels.placeholder'),
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.placeholder,
        },
        {
          name: 'chatbotConfig.initialMessage',
          label: t('glossary:labels.initialMessage'),
          type: 'text',
          uiControl: 'textarea',
          defaultValue: t('chats:texts.initialMessage'),
          showOptional: false,
        },
        {
          name: 'chatbotConfig.bubbleTitle',
          label: t('glossary:labels.bubbleTitle'),
          defaultValue: t('chats:texts.bubbleTitle'),
          showOptional: false,
        },
        {
          name: 'chatbotConfig.systemAvatar',
          label: t('glossary:labels.systemAvatar'),
          showOptional: false,
          uiProps: {
            addonAfter: <Link className='size-4' />,
          },
        },
        {
          name: 'themeConfig.logo',
          showOptional: false,
          label: t('glossary:labels.headerLogo'),
          uiProps: {
            addonAfter: <Link className='size-4' />,
          },
        },
        {
          name: 'themeConfig.logoBubble',
          showOptional: false,
          label: t('glossary:labels.logoBubble'),
          uiProps: {
            addonAfter: <Link className='size-4' />,
          },
        },
      ],
      [t],
    );

    const handleThemeChange = (selectedValue: string) => {
      if (selectedValue in THEME_PRESETS) {
        // Get the preset colors
        const preset =
          THEME_PRESETS[selectedValue as keyof typeof THEME_PRESETS];

        setValue('themeConfig.primary', preset.primary);
        setValue('themeConfig.primaryFg', preset.primaryFg);
        setValue('themeConfig.secondary', preset.secondary);
        setValue('themeConfig.secondaryFg', preset.secondaryFg);
        setValue('themeConfig.backgroundSecondary', preset.backgroundSecondary);
        setValue('themeConfig.background', preset.background);
        setValue('themeConfig.foreground', preset.foreground);
        setValue('themeConfig.placeholder', preset.placeholder);
      }
    };

    // These fields should be visible only if the agent type is Chat
    if (currentChat?.agentType !== AgentType.Chat) {
      return null;
    }

    return (
      <Card title={t('glossary:labels.themeConfig')}>
        <div className='flex flex-col gap-y-2'>
          <Label>{t('glossary:labels.style')}</Label>
          <div className='flex gap-x-2'>
            <Tooltip delayDuration={0} title={t('glossary:labels.dark')}>
              <Button
                variant='ghost'
                outline
                onClick={() => handleThemeChange('dark')}
              >
                <Moon />
                <span className='ml-2'>Dark</span>
              </Button>
            </Tooltip>
            <Tooltip delayDuration={0} title={t('glossary:labels.light')}>
              <Button
                variant='ghost'
                outline
                onClick={() => handleThemeChange('light')}
              >
                <Sun />
                <span className='ml-2'>Light</span>
              </Button>
            </Tooltip>
          </div>
        </div>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
