import type { ChatType } from '@kanbu/schema';
import type { StateCreator } from 'zustand';

export type ChatSlice = {
  chat: null | ChatType;
  setChat: (chat: ChatType | null) => void;
};

export const chatSlice: StateCreator<ChatSlice> = set => ({
  chat: null,
  setChat: chat => set({ chat }),
});
