import type { StateCreator } from 'zustand';

import { supportedUILocales, type SupportedUILocale } from '@/locale/i18n';

export interface AppSlice {
  language: SupportedUILocale;
  setLanguage: (language: SupportedUILocale) => void;
}

export const createAppSlice: StateCreator<AppSlice> = set => ({
  language: 'cs',
  setLanguage: language => {
    if (!supportedUILocales.includes(language)) {
      console.error(`Unsupported locale: ${language}`);

      return;
    }

    set({ language });
  },
});
